import React, { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePlatformList } from '../../../admin/hooks';
import { ControlledAutocomplete, ControlledNumberInput, FormGrid, LabelValue } from '../../../shared';
import { useAuctionCustomer } from '../../hooks';

interface Props {
    auctionId: string;
}

export const AuctioningLotInputs: FC<Props> = ({ auctionId }) => {
    const { t } = useTranslation();
    const [customerBuyerNumber, setCustomerBuyerNumber] = useState<number | undefined>();

    const { data: platforms } = usePlatformList({ pageSize: 100 });
    const { data: customer } = useAuctionCustomer(auctionId, customerBuyerNumber);

    const form = useFormContext();
    const watchBuyerNumber = form.watch('buyerNumber');

    const onBlurBuyerNumber = () => {
        if (watchBuyerNumber) {
            setCustomerBuyerNumber(Number(watchBuyerNumber));
            const platform = platforms?.data.find(
                (p) =>
                    !!p.firstBuyerNumber &&
                    !!p.lastBuyerNumber &&
                    Number(watchBuyerNumber) <= p.lastBuyerNumber &&
                    Number(watchBuyerNumber) >= p.firstBuyerNumber,
            );
            form.setValue('platform', platform);
        }
    };

    return (
        <FormGrid size={{ xs: 12, md: 6 }}>
            <ControlledNumberInput
                name="buyerNumber"
                label={t('buyerNumber')}
                onBlur={() => onBlurBuyerNumber()}
                minWidth="fit-content"
            />
            <LabelValue label={t('customer')} value={customer?.name} />
            <ControlledAutocomplete
                name="platform"
                label={t('platform')}
                options={platforms?.data || []}
                getOptionLabel={(platform) => platform.name || ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                minWidth="fit-content"
            />
            <ControlledNumberInput name="hammerPrice" label={t('hammerPrice')} minWidth="fit-content" />
        </FormGrid>
    );
};
