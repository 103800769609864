import { IAbbreviation } from '../../shared';

export function descriptionReplacementUtil(
    input: string,
    language: 'nl' | 'en',
    abbreviationsMap: Record<string, IAbbreviation>,
) {
    const description: string[] = [];
    const inputAbbreviations = input?.replaceAll('\n', ' \n ').split(' ') || [];

    inputAbbreviations.forEach((word) => {
        const lastChar = /[.,!?:]$/.test(word) ? word.charAt(word.length - 1) : '';
        const abbreviation = abbreviationsMap[lastChar.length > 0 ? word.substring(0, word.length - 1) : word];

        if (language === 'nl') {
            if (abbreviation) {
                description.push((abbreviation?.['descriptionNl'] || '').concat(lastChar));
            } else {
                description.push(word);
            }
        }

        if (language === 'en') {
            if (abbreviation) {
                description.push((abbreviation?.['descriptionEn'] || '').concat(lastChar));
            }
        }
    });

    return `${description.join(' ').replaceAll(' \n ', '\n')}`;
}
