import { Button, Stack } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormGrid, InUseWarning, IParams, Page, RemoveModal } from '../../../shared';
import { isBadRequest } from '../../../shared/utils/exception.util';
import { PaymentsToSupplier } from '../../components/payments-to-supplier/payments-to-supplier.component';
import { SupplierDataConsignments } from '../../components/supplier-data-consignments/supplier-data-consignments.component';
import { SupplierDataDetail } from '../../components/supplier-data-detail/supplier-data-detail.component';
import { useDeleteSupplier, useSupplier } from '../../hooks';

export const SupplierDetailPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: supplier, isPending } = useSupplier(id);
    const { mutateAsync: deleteSupplier } = useDeleteSupplier();
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);

    const onDelete = useCallback(
        async (id: string) => {
            try {
                await deleteSupplier(id);
                navigate(`/article/suppliers`);
            } catch (err: unknown) {
                if (isBadRequest(err)) {
                    return setShowDeleteWarning(true);
                }
                throw err;
            }
        },
        [deleteSupplier, navigate],
    );

    return (
        <Page
            title={supplier ? `${supplier.sequenceNumber} - ${supplier.name}` : ''}
            onBack={() => navigate(-1)}
            loading={isPending}
            actions={
                supplier && [
                    <RemoveModal
                        handleDelete={() => onDelete(supplier.id)}
                        button={<Button variant="contained">{t('delete')}</Button>}
                        title={t('supplierDeleteWarningTitle')}
                        text={t('supplierDeleteWarningText')}
                    />,
                ]
            }
        >
            {supplier && (
                <Stack spacing={2}>
                    <SupplierDataDetail supplier={supplier} />
                    <FormGrid size={{ xs: 12, lg: 6 }}>
                        <SupplierDataConsignments supplier={supplier} />
                        <PaymentsToSupplier supplier={supplier} />
                    </FormGrid>
                </Stack>
            )}

            <InUseWarning
                show={showDeleteWarning}
                onClose={() => setShowDeleteWarning(false)}
                text={t('supplierHasActiveArticles')}
            />
        </Page>
    );
};
