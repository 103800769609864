import { Button, DialogActions, DialogContent } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ILotQuery, IParams, LotStatus, TableComponent } from '../../../shared';
import { useLotsList, useSetLotStatus } from '../../hooks';

interface Props {
    buyer: ILotQuery;
    close: () => void;
}

export const PaymentSelectLots: FC<Props> = ({ buyer, close }) => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const [selectedLots, setSelectedLots] = useState<string[]>([]);

    const { data: lots } = useLotsList({ auctionId: id, pageSize: 1000, ...buyer });
    const { mutateAsync: setPaidStatus, isPending } = useSetLotStatus();

    const filteredLots = useMemo(
        () => lots?.data.filter(({ status }) => [LotStatus.SOLD, LotStatus.PAID].includes(status)) || [],
        [lots],
    );

    useEffect(() => {
        setSelectedLots(filteredLots.filter(({ status }) => status === LotStatus.PAID).map(({ id }) => id));
    }, [filteredLots]);

    const columns: GridColDef[] = [
        {
            field: 'lotNumberAndSuffix',
            headerName: t('lotNumber'),
            flex: 1,
            minWidth: 100,
            valueFormatter: (value, row) => `${row.lotNumber}${row.lotNumberSuffix || ''}`,
        },
        { field: 'hammerPrice', headerName: t('hammerPrice'), width: 140 },
        { field: 'descriptionNl', headerName: t('description'), flex: 5, minWidth: 200 },
        { field: 'status', headerName: t('status'), width: 120, valueFormatter: (value) => t(value) },
    ];

    const registerPayment = async () => {
        await setPaidStatus({
            paidLotIds: selectedLots,
            soldLotIds: filteredLots.filter(({ id }) => !selectedLots.includes(id)).map(({ id }) => id) || [],
        });
        close();
    };
    return (
        <>
            <DialogContent sx={{ minHeight: 200 }}>
                <TableComponent
                    checkboxSelection
                    rowSelectionModel={selectedLots}
                    onRowSelectionModelChange={(ids) => setSelectedLots(ids as string[])}
                    rows={filteredLots}
                    columns={columns}
                    mode="client"
                    pageSizeOptions={[100]}
                    hideFooter={(lots?.data?.length || 0) <= 100}
                    pageSize={100}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={registerPayment} disabled={isPending}>
                    {t('registerPayments')}
                </Button>
                <Button onClick={close} color="secondary">
                    {t('cancel')}
                </Button>
            </DialogActions>
        </>
    );
};
