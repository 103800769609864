import { DialogContent, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocationList } from '../../../admin/hooks';
import { ControlledInput, ControlledNumberInput, ControlledSelect } from '../../../shared';

export const SaveAuctionDialogInputs: FC = () => {
    const { t } = useTranslation();
    const { data: locations } = useLocationList({});

    return (
        <DialogContent>
            <Stack spacing={2} mt={2}>
                <Stack direction="row" spacing={2}>
                    <ControlledSelect name="location" label={t('location')} required>
                        {Object.values(locations?.data || []).map((location) => (
                            <MenuItem value={location.name} key={location.id}>
                                {location.name}
                            </MenuItem>
                        ))}
                    </ControlledSelect>

                    <ControlledNumberInput
                        name="fixedCostPerLot"
                        label={t('fixedCostPerLot')}
                        slotProps={{ input: { startAdornment: <InputAdornment position="start" children="€" /> } }}
                    />
                </Stack>

                <ControlledInput name="description" label={t('description')} required multiline rows={4} />

                <Typography>{t('pickupInfo')}</Typography>
                <Stack
                    sx={{
                        whiteSpace: 'nowrap',
                        '& textarea::placeholder': { fontStyle: 'italic', fontSize: '14px', lineHeight: '18px' },
                    }}
                    direction="row"
                    spacing={2}
                >
                    <ControlledInput
                        name="pickupInfo.NL"
                        label={'NL'}
                        multiline
                        rows={6}
                        placeholder={t('pickupInfoExampleNL')}
                    />
                    <ControlledInput
                        name="pickupInfo.FR"
                        label={'FR'}
                        multiline
                        rows={6}
                        placeholder={t('pickupInfoExampleFR')}
                    />
                    <ControlledInput
                        name="pickupInfo.EN"
                        label={'EN'}
                        multiline
                        rows={6}
                        placeholder={t('pickupInfoExampleEN')}
                    />
                </Stack>
            </Stack>
        </DialogContent>
    );
};
