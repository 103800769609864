import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { lazy, mixed, number, object, string } from 'yup';
import { IPlatform, LotStatus } from '../../shared';

export function useAuctioningLotSchema() {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object().shape({
                buyerNumber: lazy((value) => {
                    return value === ''
                        ? string().when('status', { is: LotStatus.SOLD, then: (schema) => schema.required() })
                        : number()
                              .nullable()
                              .when('status', { is: LotStatus.SOLD, then: (schema) => schema.required() });
                }),
                hammerPrice: lazy((value) => {
                    return value === ''
                        ? string().when('status', { is: LotStatus.SOLD, then: (schema) => schema.required() })
                        : number()
                              .nullable()
                              .when('status', { is: LotStatus.SOLD, then: (schema) => schema.required() });
                }),
                status: mixed<LotStatus>().optional(),
                platform: mixed<IPlatform>()
                    .when('status', { is: LotStatus.SOLD, then: (schema) => schema.required() })
                    .nullable()
                    .optional(),
            }),
        [t],
    );
}
