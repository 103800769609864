import { Box } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useConditions } from '../../../admin/hooks';
import { FormGrid, ILotForSignature, Widget } from '../../../shared';
import { CustomerInfo } from './customer-info.component';
import { DrawSignature } from './draw-signature.component';
import { LotInfo } from './lot-info.component';

interface Props {
    currentLot: ILotForSignature;
}

export const Signature: FC<Props> = ({ currentLot }) => {
    const { t } = useTranslation();
    const { data: conditions } = useConditions();

    return (
        <FormGrid size={{ md: 12, lg: 6 }}>
            <Box>
                <FormGrid size={{ md: 12, lg: 6 }} itemProps={{ mb: 2 }}>
                    <CustomerInfo currentLot={currentLot} />
                    <LotInfo currentLot={currentLot} />
                </FormGrid>
                <Widget title={t('conditions')} sx={{ whiteSpace: 'pre-line' }}>
                    {conditions?.auction}
                </Widget>
            </Box>
            <DrawSignature currentLot={currentLot} />
        </FormGrid>
    );
};
