import React, { FC } from 'react';
import { FormGrid, IArticle } from '../../../shared';
import { ArticleDescriptionInputs } from './article-description-inputs.component';
import { ArticleEstimateInputs } from './article-estimate-inputs.component';
import { ArticleGeneralInputs } from './article-general-inputs.component';

interface Props {
    article?: IArticle;
}

export const ArticleInputs: FC<Props> = ({ article }) => {
    return (
        <>
            <FormGrid size={{ xs: 12, md: 6 }}>
                <ArticleGeneralInputs article={article} />
                <ArticleEstimateInputs />
            </FormGrid>
            <ArticleDescriptionInputs />
        </>
    );
};
