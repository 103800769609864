import { Button, DialogActions, DialogContent } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ILotQuery, IParams, LotStatus, TableComponent } from '../../../shared';
import { useCreditLots, useLotsList } from '../../hooks';

interface Props {
    buyer: ILotQuery;
    close: () => void;
}

export const CreditSelectLots: FC<Props> = ({ buyer, close }) => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const [selectedLots, setSelectedLots] = useState<string[]>([]);

    const { data: lots } = useLotsList({ auctionId: id, pageSize: 1000, ...buyer, status: LotStatus.SOLD });
    const { mutateAsync: creditLots, isPending } = useCreditLots();

    const columns: GridColDef[] = [
        {
            field: 'lotNumberAndSuffix',
            headerName: t('lotNumber'),
            flex: 1,
            minWidth: 100,
            valueFormatter: (value, row) => `${row.lotNumber}${row.lotNumberSuffix || ''}`,
        },
        { field: 'hammerPrice', headerName: t('hammerPrice'), width: 140 },
        { field: 'descriptionNl', headerName: t('description'), flex: 5, minWidth: 200 },
    ];

    const onCreditLots = async () => {
        await creditLots({
            lotIds: selectedLots,
        });
        close();
    };
    return (
        <>
            <DialogContent sx={{ minHeight: 200 }}>
                <TableComponent
                    checkboxSelection
                    rowSelectionModel={selectedLots}
                    onRowSelectionModelChange={(ids) => setSelectedLots(ids as string[])}
                    rows={lots?.data || []}
                    columns={columns}
                    mode="client"
                    pageSizeOptions={[100]}
                    hideFooter={(lots?.data?.length || 0) <= 100}
                    pageSize={100}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onCreditLots}
                    disabled={isPending || selectedLots.length === 0}
                >
                    {t('creditLots')}
                </Button>
                <Button onClick={close} color="secondary" disabled={isPending}>
                    {t('cancel')}
                </Button>
            </DialogActions>
        </>
    );
};
