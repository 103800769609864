import { yupResolver } from '@hookform/resolvers/yup';
import { Button, InputAdornment } from '@mui/material';
import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDefaultAuctionCost, useSaveDefaultAuctionCost } from '../../hooks';
import { useDefaultAuctionCostSchema } from '../../validators';
import { ControlledNumberInput, FormCard, FormGrid, IDefaultAuctionCost, Page, Section } from '../../../shared';

export const DefaultAuctionCostEditPage: FC = () => {
    const { t } = useTranslation();

    const { data: defaultAuctionCost, isPending: isLoading } = useDefaultAuctionCost();
    const { mutateAsync: saveDefaultAuctionCost, isPending: isSaving } = useSaveDefaultAuctionCost();

    const form = useForm({
        resolver: yupResolver(useDefaultAuctionCostSchema()),
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (defaultAuctionCost) {
            form.reset(defaultAuctionCost);
        }
    }, [defaultAuctionCost, form]);

    const onSubmit = useCallback(
        async (item: IDefaultAuctionCost) => {
            await saveDefaultAuctionCost(item);
        },
        [saveDefaultAuctionCost],
    );
    return (
        <Page title={t('defaultAuctionCosts')} loading={isLoading}>
            <FormProvider {...form}>
                <FormCard
                    handleSubmit={form.handleSubmit(onSubmit)}
                    actions={
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={form.handleSubmit(onSubmit)}
                            disabled={isSaving}
                        >
                            {t('save')}
                        </Button>
                    }
                >
                    <Section title={t('perLot')}>
                        <FormGrid>
                            <ControlledNumberInput
                                name="fixedCostPerLot"
                                label={t('fixedCostPerLot')}
                                slotProps={{
                                    input: { startAdornment: <InputAdornment position="start" children="€" /> },
                                }}
                                sx={{ input: { pl: 1 } }}
                            />
                        </FormGrid>
                    </Section>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
