import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, lazy, mixed, number, object, string } from 'yup';
import { dimensionSchema } from '../../article/validators/dimension.validator';
import { IArtist, ICategory, ILotArticleForm } from '../../shared';

export function useLotSchema() {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object().shape({
                lotNumber: lazy((value) => {
                    return value === ''
                        ? string().typeError(t('invalidNumber')).required()
                        : number().min(0).typeError(t('invalidNumber')).required();
                }),
                lotNumberSuffix: string()
                    .nullable()
                    .test('lengthCheck', t('maxSuffixLength'), (value) => {
                        if (value == undefined) {
                            return true;
                        }
                        return (value?.length || 0) <= 1;
                    })
                    .matches(/^([^0-9]*)$/, t('invalidLotNumberSuffix')),
                minEstimate: lazy((value) => {
                    return value === ''
                        ? string()
                        : number()
                              .min(0)
                              .transform((value) => (isNaN(value) ? null : value))
                              .typeError(t('invalidNumber'))
                              .nullable();
                }),
                maxEstimate: lazy((value) => {
                    return value === ''
                        ? string()
                        : number()
                              .min(0)
                              .transform((value) => (isNaN(value) ? null : value))
                              .typeError(t('invalidNumber'))
                              .nullable();
                }),
                descriptionNl: string().required(),
                descriptionEn: string().required(),
                articles: array()
                    .of(
                        object().shape({
                            id: string()
                                .test('length', t('required'), (value) => (value?.length || 0) > 0)
                                .required(),
                            shortDescription: string().optional(),
                            descriptionNl: string().required(t('required')),
                            descriptionEn: string().required(t('required')),
                            artist: mixed<IArtist>()
                                .transform((value) => (value ? value : undefined))
                                .optional()
                                .nullable(),
                            categories: array()
                                .of(mixed<ICategory>().required())
                                .required()
                                .min(1, t('noCategorySelected')),
                            dimensions: dimensionSchema().optional(),
                            percentage: lazy((value) => {
                                return value === ''
                                    ? string().typeError(t('invalidNumber')).required()
                                    : number().min(0).typeError(t('invalidNumber')).required();
                            }),
                        }),
                    )
                    .test('percentage', t('percentageToHigh'), (value) => getTotal(value) <= 100)
                    .test('percentage', t('percentageToLow'), (value) => getTotal(value) >= 100)
                    .min(1, t('noArticleSelected'))
                    .required(),
            }),
        [t],
    );
}

function getTotal(items?: ILotArticleForm[]) {
    const total = items?.reduce((total, { percentage }) => total + (Number(percentage) || 0), 0) || 0;
    return parseFloat(total.toFixed(2));
}
