import Grid from '@mui/material/Grid2';
import { ReactNode } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Panel, PanelContent, PanelFooter } from '..';

export function FormCard(props: { children: ReactNode; actions: ReactNode; handleSubmit: SubmitHandler<any> }) {
    const actions = Array.isArray(props.actions) ? props.actions : [props.actions];

    return (
        <form onSubmit={props.handleSubmit} noValidate={true}>
            <Panel>
                <PanelContent>{props.children}</PanelContent>

                {props.actions && (
                    <PanelFooter>
                        <Grid container alignItems="center" spacing={1}>
                            {actions.map((action, index) => (
                                <Grid key={index}>{action}</Grid>
                            ))}
                        </Grid>
                    </PanelFooter>
                )}
            </Panel>
        </form>
    );
}
