import { Checkbox, MenuItem, Stack, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCategoriesList } from '../../../admin/hooks';
import {
    checkDifferentFilterAndFormValues,
    ConsignmentType,
    ControlledNumberInput,
    ControlledSelect,
    FormGrid,
    IArticleQuery,
    IArticleQueryForm,
    Options,
    OptionsToggle,
    PermissionKeys,
    SaleStatus,
    useHasPermission,
} from '../../../shared';
import { SelectAutocompleteSupplier } from '../save-consignment-dialog/select-autocomplete-supplier.component';

interface Props {
    filter: Record<string, any>;
    onChange: (filter: IArticleQuery & { options: Options }) => void;
}

export const ArticleFilter: FC<Props> = ({ filter, onChange }) => {
    const { t } = useTranslation();
    const { hasPermission } = useHasPermission();
    const hasReturnsPermission = hasPermission(PermissionKeys.RETURNS_READ);
    const hasInactiveArticlePermission = hasPermission(PermissionKeys.INACTIVE_ARTICLES_READ);
    const form = useForm<IArticleQueryForm>({ mode: 'onChange' });

    const defaultOptions: Options = useMemo(() => {
        const extraOptions: Options =
            hasReturnsPermission || hasInactiveArticlePermission
                ? {
                      options: { type: 'title', label: t('extraOptions') },
                      ...(hasReturnsPermission
                          ? { includeReturns: { label: t('includeReturns'), active: false } }
                          : {}),
                      ...(hasInactiveArticlePermission
                          ? { includeInactive: { label: t('includeInactive'), active: false } }
                          : {}),
                  }
                : {};
        return {
            filter: { type: 'title', label: t('filter') },
            consignmentTypes: { label: t('consignmentTypes'), active: false },
            startRange: { label: t('startRange'), active: false },
            endRange: { label: t('endRange'), active: false },
            saleStatus: { label: t('saleStatus'), active: false },
            isOfferedInAuction: { label: t('isOfferedInAuction'), active: false },
            categories: { label: t('categories'), active: false },
            ...extraOptions,
        };
    }, [t, hasReturnsPermission, hasInactiveArticlePermission]);

    const setOptions = useCallback((options: Options) => onChange({ ...filter, options }), [filter, onChange]);
    const formValues = form.watch();

    const { data: categories } = useCategoriesList({ pageSize: 1000 });

    const options: Options = useMemo(() => {
        return filter.options || defaultOptions;
    }, [defaultOptions, filter.options]);

    useEffect(() => {
        form.reset({ ...filter, supplier: filter.supplier ?? '' });
    }, [filter, form]);

    useEffect(() => {
        const { consignmentTypes, startRange, endRange, saleStatus, isOfferedInAuction, categories } = options;
        if (!consignmentTypes?.active) form.setValue('consignmentTypes', undefined);
        if (!startRange?.active) {
            form.setValue('startRange1', '');
            form.setValue('startRange2', '');
            form.setValue('startRange3', '');
        }
        if (!endRange?.active) {
            form.setValue('endRange1', '');
            form.setValue('endRange2', '');
            form.setValue('endRange3', '');
        }
        if (!saleStatus?.active) form.setValue('saleStatus', undefined);
        if (!isOfferedInAuction?.active) form.setValue('isOfferedInAuction', undefined);
        if (!categories?.active) form.setValue('categories', undefined);
    }, [options, form]);

    useEffect(() => {
        if (!!Object.keys(formValues).length && checkDifferentFilterAndFormValues(formValues, filter)) {
            onChange({ ...formValues, options: options });
        }
    }, [formValues, filter, options, onChange]);

    return (
        <FormProvider {...form}>
            <FormGrid containerProps={{ spacing: 1, rowGap: 1, alignItems: 'center' }}>
                <OptionsToggle options={options} onChange={setOptions} />
                <SelectAutocompleteSupplier sx={{ minWidth: '250px' }} size="small" isFilter />

                {options.consignmentTypes.active && (
                    <ControlledSelect
                        name="consignmentTypes"
                        label={t('consignmentTypes')}
                        size="small"
                        multiple
                        minWidth={150}
                    >
                        {Object.values(ConsignmentType || []).map((type) => (
                            <MenuItem value={type} key={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}
                {options.startRange.active && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ '*.MuiFormControl-root': { minWidth: '60px', width: '65px' } }}
                    >
                        <Typography variant="body2" textAlign="center" sx={{ width: '90px' }}>
                            {t('fromArticleNumber')}
                        </Typography>
                        <ControlledNumberInput name="startRange1" size="small" decimalScale={0} />
                        <Typography sx={{ p: 1 }}>/</Typography>
                        <ControlledNumberInput name="startRange2" size="small" decimalScale={0} />
                        <Typography sx={{ p: 1 }}>/</Typography>
                        <ControlledNumberInput name="startRange3" size="small" decimalScale={0} />
                    </Stack>
                )}
                {options.endRange.active && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ '*.MuiFormControl-root': { minWidth: '60px', width: '65px' } }}
                    >
                        <Typography variant="body2" textAlign="center" sx={{ width: '90px' }}>
                            {t('tillArticleNumber')}
                        </Typography>
                        <ControlledNumberInput name="endRange1" size="small" decimalScale={0} />
                        <Typography sx={{ p: 1 }}>/</Typography>
                        <ControlledNumberInput name="endRange2" size="small" decimalScale={0} />
                        <Typography sx={{ p: 1 }}>/</Typography>
                        <ControlledNumberInput name="endRange3" size="small" decimalScale={0} />
                    </Stack>
                )}
                {options.saleStatus.active && (
                    <ControlledSelect
                        name="saleStatus"
                        label={t('saleStatus')}
                        size="small"
                        minWidth={200}
                        multiple
                        renderValue={(selected) => (selected as string[])?.map((item) => t(item)).join(', ')}
                    >
                        {Object.values(SaleStatus || []).map((status) => (
                            <MenuItem value={status} key={status}>
                                <Typography>
                                    <Checkbox checked={filter?.saleStatus?.includes(status)} />
                                    {t(status)}
                                </Typography>
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}
                {options.isOfferedInAuction.active && (
                    <ControlledSelect
                        name="isOfferedInAuction"
                        label={t('isOfferedInAuction')}
                        size="small"
                        minWidth={200}
                    >
                        <MenuItem value="" sx={{ p: 2 }} />
                        <MenuItem value="true">{t('isOffered')}</MenuItem>
                        <MenuItem value="false">{t('isNotOffered')}</MenuItem>
                    </ControlledSelect>
                )}
                {options.categories.active && (
                    <ControlledSelect
                        name="categories"
                        label={t('categories')}
                        size="small"
                        minWidth={200}
                        multiple
                        defaultChecked
                    >
                        {Object.values(categories?.data || []).map(({ id, descriptionNl }) => (
                            <MenuItem value={id} key={id}>
                                {descriptionNl}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}
            </FormGrid>
        </FormProvider>
    );
};
