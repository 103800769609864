export enum QueryKeys {
    AuthUser = 'AuthUser',
    AuthProviders = 'AuthProviders',
    Status = 'Status',
    UserRoles = 'UserRoles',
    Artists = 'Artists',
    Artist = 'Artist',
    Categories = 'Categories',
    Category = 'Category',
    Abbreviations = 'Abbreviations',
    Abbreviation = 'Abbreviation',
    Suppliers = 'Suppliers',
    Supplier = 'Supplier',
    Articles = 'Articles',
    Article = 'Article',
    Consignments = 'Consignments',
    Consignment = 'Consignment',
    ConsignmentArticles = 'ConsignmentArticles',
    NextArticleNumber = 'NextArticleNumber',
    Auctions = 'Auctions',
    Auction = 'Auction',
    Lots = 'Lots',
    Lot = 'Lot',
    AuctionSlots = 'AuctionSlots',
    AuctionSlot = 'AuctionSlot',
    AuctionMissingLots = 'AuctionMissingLots',
    Customers = 'Customers',
    Customer = 'Customer',
    DefaultAuctionCost = 'DefaultAuctionCost',
    Buyers = 'Buyers',
    Buyer = 'Buyer',
    Locations = 'Locations',
    Location = 'Location',
    Conditions = 'Conditions',
    Platforms = 'Platforms',
    Platform = 'Platform',
    PurchaseRegisters = 'PurchaseRegisters',
    PurchaseRegister = 'PurchaseRegister',
    SupplierPayments = 'SupplierPayments',
}
