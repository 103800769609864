import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LotStatus, Page } from '../../../shared';
import { ActiveSlotNavigation } from '../../components';
import { ActiveSlotForm } from '../../components/active-slot/active-slot-form.component';
import { ActiveSlotHistory } from '../../components/active-slot/active-slot-history.component';
import { useGetAuctionLots } from '../../hooks';

// eslint-disable-next-line
type Params = {
    id: string;
    first: string;
    last: string;
};

export const ActiveAuctionSlotPage: FC = () => {
    const { id, first, last } = useParams<Params>() as Params;
    const { search } = useLocation();
    const navigate = useNavigate();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const current = params.get('current');
    const [lastLot, setLastLot] = useState(false);

    const { data: lots } = useGetAuctionLots(id, { firstLotNumber: Number(first), lastLotNumber: Number(last) });

    const currentLot = useMemo(() => {
        document
            .getElementsByClassName('history')[0]
            ?.getElementsByClassName('MuiDataGrid-cell')[0]
            ?.scrollIntoView(false);
        document
            .getElementsByClassName('history')[1]
            ?.getElementsByClassName('MuiDataGrid-cell')[0]
            ?.scrollIntoView(false);

        if (current) {
            return lots?.find(({ lotNumber, lotNumberSuffix }) => `${lotNumber}${lotNumberSuffix || ''}` === current);
        }
        return lots?.find((lot) => lot.status === LotStatus.AWAITING_AUCTION) || lots?.[0];
    }, [lots, current]);
    const currentLotIndex = useMemo(() => lots?.findIndex((l) => l.id === currentLot?.id), [lots, currentLot]);

    useEffect(() => {
        if (lots?.length) {
            setLastLot(currentLotIndex === lots.length - 1);
        }
    }, [currentLot, currentLotIndex, lots?.length]);

    const onNext = () => {
        if (lots && currentLotIndex !== undefined) {
            const lot = lots[currentLotIndex + 1];
            if (lastLot) {
                navigate(`/auction/auctions/${id}/detail`);
            } else {
                navigate(`?current=${lot?.lotNumber}${lot?.lotNumberSuffix || ''}`);
            }
        }
    };

    return (
        <Page>
            {currentLot && (
                <Box sx={{ height: 'calc(100vh - 180px)' }}>
                    <ActiveSlotNavigation lots={lots} auctionId={id} currentLot={currentLot} onNext={onNext} />
                    <Grid container alignItems="stretch" spacing={2} sx={{ height: '100%' }}>
                        <ActiveSlotHistory status={LotStatus.SOLD} lots={lots} />
                        <ActiveSlotForm currentLot={currentLot} onNext={onNext} auctionId={id} />
                        <ActiveSlotHistory status={LotStatus.NOT_SOLD} lots={lots} />
                    </Grid>
                </Box>
            )}
        </Page>
    );
};
