import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, MenuItem, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledSelect,
    DialogTitleWithClose,
    IAuctioningLotForm,
    ILot,
    LabelValue,
    LotStatus,
} from '../../../shared';
import { useAuctioningLot } from '../../hooks';
import { auctioningLotFromFormMapper, auctioningLotToFormMapper } from '../../mappers/auctioning-lot.mapper';
import { useAuctioningLotSchema } from '../../validators/auctioning-lot.validator';
import { AuctioningLotInputs } from '../auctioning-lot-inputs/auctioning-lot-inputs.component';

interface Props {
    open: boolean;
    close: () => void;
    auctionId: string;
    lot?: ILot;
}

export const AuctioningLotModal: FC<Props> = ({ open, close, auctionId, lot }) => {
    const { t } = useTranslation();

    const { mutateAsync: saveLot, isPending } = useAuctioningLot();

    const form = useForm<IAuctioningLotForm>({ resolver: yupResolver(useAuctioningLotSchema()), mode: 'onSubmit' });

    useEffect(() => {
        if (lot) {
            form.reset(auctioningLotToFormMapper(lot));
        }
    }, [lot, form]);

    const onSubmit = async (item: IAuctioningLotForm) => {
        if (lot) {
            await saveLot({ id: lot.id, item: { ...auctioningLotFromFormMapper(item) } });
            close();
        }
    };
    if (!lot) return;
    return (
        <Dialog open={open} onClose={close} fullWidth maxWidth="lg">
            <FormProvider {...form}>
                <form noValidate={true} autoComplete="off">
                    <DialogTitleWithClose onClose={close}>{t('editLot')}</DialogTitleWithClose>

                    <DialogContent>
                        <Grid container spacing={2} sx={{ pt: 2 }}>
                            <Grid size={{ xs: 12, md: 4 }}>
                                <Stack spacing={2}>
                                    <LabelValue
                                        label={t('lotNumber')}
                                        value={`${lot?.lotNumber || ''} ${lot?.lotNumberSuffix || ''}`}
                                    />

                                    <Box>
                                        <Typography variant="caption">{t('description')}</Typography>
                                        <Typography
                                            sx={{
                                                whiteSpace: 'pre-line',
                                                width: '95%',
                                                height: '150px',
                                                overflow: 'scroll',
                                            }}
                                        >
                                            {lot.descriptionNl}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid size={{ xs: 12, md: 8 }}>
                                <Stack spacing={2}>
                                    <AuctioningLotInputs auctionId={auctionId} />

                                    {[LotStatus.AWAITING_AUCTION, LotStatus.SOLD, LotStatus.NOT_SOLD].includes(
                                        lot.status,
                                    ) ? (
                                        <ControlledSelect name="status" label={t('status')}>
                                            <MenuItem value={LotStatus.SOLD}>{t(LotStatus.SOLD)}</MenuItem>
                                            <MenuItem value={LotStatus.NOT_SOLD}>{t(LotStatus.NOT_SOLD)}</MenuItem>
                                        </ControlledSelect>
                                    ) : (
                                        <LabelValue label={t('status')} value={t(lot.status)} />
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Stack direction="row" spacing={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={form.handleSubmit(onSubmit)}
                                disabled={isPending}
                            >
                                {t('save')}
                            </Button>
                            <Button onClick={close} color="secondary">
                                {t('cancel')}
                            </Button>
                        </Stack>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};
