import { AddCircle, Article, Search } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    formatPercentage,
    formatPrice,
    IArtist,
    IConsignment,
    PermissionKeys,
    RowActions,
    useHasPermission,
    Widget,
} from '../../../shared';
import { useConsignmentArticles, useDeleteArticle } from '../../hooks';

interface Props {
    consignment: IConsignment;
}

export const ArticleData: FC<Props> = ({ consignment }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasReturnsPermission = hasPermission(PermissionKeys.RETURNS_READ);
    const [includeReturns, setIncludeReturns] = useState(false);

    const { data: articles, isLoading: articlesIsLoading } = useConsignmentArticles(consignment.id, includeReturns);

    const { mutateAsync: deleteArticle } = useDeleteArticle();

    const columns: GridColDef[] = [
        { field: 'articleNumber', headerName: t('articleNumber'), sortable: false, minWidth: 150 },
        {
            field: 'paidToSupplier',
            headerName: t('paidStatus'),
            width: 120,
            sortable: false,
            valueFormatter: (value: boolean) => (value ? t('paidToSupplier') : t('notPaidToSupplier')),
        },
        { field: 'descriptionNl', headerName: t('descriptionNl'), sortable: false, minWidth: 100, flex: 1 },
        {
            field: 'artist',
            headerName: t('artist'),
            sortable: false,
            minWidth: 100,
            flex: 1,
            valueFormatter: (value: IArtist) => (value ? `${value?.lastname} ${value?.firstname}` : ''),
        },
        {
            field: 'commission',
            headerName: t('commission'),
            sortable: false,
            minWidth: 50,
            flex: 1,
            valueFormatter: (value: number) => formatPercentage(value),
        },
        {
            field: 'minSalePrice',
            headerName: t('minSalePrice'),
            sortable: false,
            minWidth: 50,
            flex: 1,
            valueFormatter: (value: number) => formatPrice(value),
        },
        ...(includeReturns
            ? [{ field: 'returnDate', headerName: t('returnDate'), sortable: false, minWidth: 50 }]
            : []),
        {
            field: ' ',
            headerName: '',
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            navigate(`/article/articles/${id}/detail`, {
                                state: {
                                    redirect: `/article/consignments/${consignment.id}`,
                                    consignment: consignment,
                                },
                            });
                        }}
                    >
                        <Search />
                    </IconButton>
                    <RowActions
                        onEdit={() =>
                            navigate(`/article/articles/${id}/edit`, {
                                state: {
                                    redirect: `/article/consignments/${consignment.id}`,
                                    consignment: consignment,
                                },
                            })
                        }
                        onDelete={() => deleteArticle(id)}
                        deleteWarningTitle={t('articleDeleteWarningTitle')}
                        deleteWarningText={t('articleDeleteWarningText')}
                    />
                </>
            ),
        },
    ];

    return (
        <Widget
            title={t('articles')}
            icon={<Article />}
            hasTable
            sx={{ mt: 2 }}
            actions={
                <>
                    {hasReturnsPermission && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={includeReturns}
                                    onChange={(event) => setIncludeReturns(event.target.checked)}
                                />
                            }
                            label={t('includeReturns')}
                        />
                    )}

                    <IconButton
                        onClick={() =>
                            navigate('/article/articles/new', {
                                state: {
                                    consignment,
                                    redirect: `/article/consignments/${consignment.id}`,
                                },
                            })
                        }
                    >
                        <AddCircle color="primary" />
                    </IconButton>
                </>
            }
        >
            <DataGrid
                rows={articles || []}
                columns={columns}
                disableColumnFilter
                disableColumnMenu
                columnHeaderHeight={40}
                rowHeight={45}
                sortModel={[{ field: 'articleNumber', sort: 'asc' as GridSortDirection }]}
                hideFooter
                slots={{ noRowsOverlay: () => <Box display="hidden" /> }}
                loading={articlesIsLoading}
            />
        </Widget>
    );
};
