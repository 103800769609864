import { getCountries, IOrigins, ISaveSupplier, ISupplier, ISupplierForm } from '../../shared';

export const supplierToFormMapper = (item: ISupplier): ISupplierForm => {
    return {
        ...item,
        address: {
            ...item.address,
            country: getCountries.find(
                ({ label }) => label.toLowerCase() === item.address.country.toLowerCase(),
            ) as IOrigins,
        },
    };
};

export const supplierFromFormMapper = (item: ISupplierForm): ISaveSupplier => {
    return {
        ...item,
        address: { ...item.address, country: item.address.country.label },
    };
};
