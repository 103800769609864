import { Button, Chip, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDateString, FormGrid, IParams, Page } from '../../../shared';
import { useArticle } from '../../hooks';
import {
    ArticleDetailDescription,
    ArticleDetailEstimates,
    ArticleDetailHistory,
    ArticleDetailInformation,
    ReturnArticle,
} from '../../components';

export const ArticleDetailPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: article, isFetching } = useArticle(id);

    return (
        <Page
            title={
                article && (
                    <Stack direction="row" justifyItems="center" spacing={1}>
                        <Typography variant="subtitle1">
                            {t('article')} - {`${article.consignment.consignmentNumber}/${article?.articleNumber}`}
                        </Typography>
                        <Chip
                            label={article?.paidToDVC ? t('paidToDVCChip') : t('notPaidToDVCChip')}
                            variant="outlined"
                            color={article?.paidToDVC ? 'success' : 'warning'}
                        />
                        <Chip
                            label={article?.paidToSupplier ? t('paidToSupplierChip') : t('notPaidToSupplierChip')}
                            variant="outlined"
                            color={article?.paidToSupplier ? 'success' : 'warning'}
                        />
                        {article.returnDate && (
                            <Chip
                                label={t('returnedOn', {
                                    returnDate: formatDateString(article.returnDate),
                                    interpolation: { escapeValue: false },
                                })}
                                variant="outlined"
                                color="error"
                            />
                        )}
                    </Stack>
                )
            }
            onBack={() => navigate(-1)}
            loading={isFetching}
            actions={
                article && [
                    <ReturnArticle article={article} />,
                    <Button variant="contained" onClick={() => navigate(`/article/articles/${id}/edit`)}>
                        {t('editArticle')}
                    </Button>,
                ]
            }
        >
            {article && (
                <>
                    <FormGrid size={{ xs: 12, md: 6 }} containerProps={{ pr: 0 }}>
                        <ArticleDetailInformation article={article} />
                        <ArticleDetailEstimates article={article} />
                        <ArticleDetailDescription article={article} />
                        <ArticleDetailHistory />
                    </FormGrid>
                </>
            )}
        </Page>
    );
};
