import { auctionApi, BaseClient, IAuctionSlot, ISaveAuctionSlot } from '../../shared';

class AuctionSlotClient extends BaseClient<IAuctionSlot, ISaveAuctionSlot> {
    constructor() {
        super(auctionApi, '/auction-slots');
    }

    public async closeAuctionSlot(slotId: string): Promise<void> {
        await this.api.post<void>(`${this.basePath}/${slotId}/close`);
    }
}
export const auctionSlotClient = new AuctionSlotClient();
