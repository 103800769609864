import Grid from '@mui/material/Grid2';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { InUseWarning, IParams, Page } from '../../../shared';
import { CustomerAssets, CustomerData } from '../../components';
import { DeleteCustomer } from '../../components/delete-customer/delete-customer.component';
import { useCustomer } from '../../hooks';

export const CustomerDetailPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();
    const [showInUseError, setShowInUseError] = useState(false);

    const { data: customer, isPending } = useCustomer(id);

    return (
        <Page
            title={customer?.name || ''}
            onBack={() => navigate(-1)}
            loading={isPending}
            actions={
                customer && [
                    <DeleteCustomer
                        customerId={id}
                        navigateTo="/auction/customers"
                        setShowInUseError={setShowInUseError}
                    />,
                ]
            }
        >
            <InUseWarning
                show={showInUseError}
                onClose={() => setShowInUseError(false)}
                text={t('customerHasUnpaidLots')}
            />
            {customer && (
                <Grid container spacing={2}>
                    <Grid size={{ xs: 12, lg: 8 }}>
                        <CustomerData customer={customer} />
                    </Grid>
                    <Grid size={{ xs: 12, lg: 4 }}>
                        <CustomerAssets customer={customer} />
                    </Grid>
                </Grid>
            )}
        </Page>
    );
};
