import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, InputAdornment, Stack } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledNumberInput, DialogTitleWithClose, ICost, ICostForm } from '../../../shared';
import { useSaveCost } from '../../hooks';
import { useCostSchema } from '../../validators';

interface Props {
    open: boolean;
    close: () => void;
    consignmentId: string;
    cost?: ICost;
}

export const SaveCostDialog: FC<Props> = ({ open, close, consignmentId, cost }) => {
    const { t } = useTranslation();

    const { mutateAsync: saveCost, isPending: isSaving } = useSaveCost();

    const form = useForm<ICostForm>({
        resolver: yupResolver(useCostSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (cost) {
            form.reset(cost);
        } else {
            form.reset({});
        }
    }, [form, open, cost]);

    const onSubmit = async (item: ICostForm) => {
        await saveCost({ id: cost?.id, item: { ...item, consignmentId } });
        close();
    };

    return (
        <Dialog open={open} onClose={close} fullWidth maxWidth="md">
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <DialogTitleWithClose onClose={close}>{cost ? t('editCost') : t('newCost')}</DialogTitleWithClose>
                    <DialogContent>
                        <Stack direction="column" spacing={2} mt={2}>
                            <ControlledInput name="description" label={t('description')} />
                            <ControlledNumberInput
                                name="amount"
                                label={t('price')}
                                slotProps={{
                                    input: { startAdornment: <InputAdornment position="start" children="€" /> },
                                }}
                                sx={{ input: { pl: 1 } }}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary" disabled={isSaving}>
                            {t('save')}
                        </Button>
                        <Button onClick={close} color="secondary" disabled={isSaving}>
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};
