import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ConsignmentType,
    IArticleQuery,
    ICategory,
    Page,
    PermissionKeys,
    RowActions,
    SaleStatus,
    SearchField,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParamsContext,
} from '../../../shared';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { useArticlesList, useDeleteArticle } from '../../hooks';
import { Button, IconButton, Tooltip } from '@mui/material';
import { CloudDownloadTwoTone, Search } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { ArticleFilter } from '../../components';

export const ArticlesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.ARTICLES_WRITE);

    const {
        queryParams: { page, pageSize, filter, sort, search },
        debouncedQueryParams: { search: debouncedSearch },
        setFilter,
        setPage,
        setPageSize,
        setSort,
        setSearch,
    } = useQueryParamsContext('articles', {
        sort: [{ field: 'articleNumber', sort: 'asc' as GridSortDirection }],
        filter: {},
    });

    const { mutateAsync: deleteArticle } = useDeleteArticle();

    const filters: Partial<IArticleQuery> = {
        search: debouncedSearch,
        consignmentTypes: (filter.consignmentTypes as unknown as ConsignmentType[]) || undefined,
        startRange:
            filter.startRange1 && filter.startRange2 && filter.startRange3
                ? JSON.stringify({ part1: filter.startRange1, part2: filter.startRange2, part3: filter.startRange3 })
                : undefined,
        endRange:
            filter.endRange1 && filter.endRange2 && filter.endRange3
                ? JSON.stringify({ part1: filter.endRange1, part2: filter.endRange2, part3: filter.endRange3 })
                : undefined,
        supplier: filter.supplier?.id || undefined,
        saleStatus: filter.saleStatus || undefined,
        isOfferedInAuction:
            filter.isOfferedInAuction && filter.isOfferedInAuction !== '' ? filter.isOfferedInAuction : undefined,
        categories: filter.categories || undefined,
        includeReturns: filter.options?.includeReturns?.active,
        includeInactive: filter.options?.includeInactive?.active,
    };

    const { data, isPending } = useArticlesList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        ...filters,
    });

    const columns: GridColDef[] = [
        {
            field: 'articleNumber',
            headerName: t('articleNumber'),
            flex: 0.85,
            valueFormatter: (_, row) => `${row.consignment?.consignmentNumber}/${row.articleNumber}`,
        },
        {
            field: 'consignmentType',
            headerName: t('consignmentTypes'),
            flex: 0.8,
        },
        {
            field: 'saleStatus',
            headerName: t('saleStatus'),
            flex: 0.8,
            valueFormatter: (value: SaleStatus) => t(value),
        },
        {
            field: 'isOfferedInAuction',
            headerName: t('isOfferedInAuction'),
            flex: 1,
            valueFormatter: (value: boolean) => (value ? t('yes') : t('no')),
        },
        {
            field: 'paidToDVC',
            headerName: t('paidStatusDVC'),
            sortable: false,
            flex: 0.85,
            valueFormatter: (value: boolean) => (value ? t('paidToDVC') : t('notPaidToDVC')),
        },
        {
            field: 'paidToSupplier',
            headerName: t('paidStatusSupplier'),
            sortable: false,
            flex: 1,
            valueFormatter: (value: boolean) => (value ? t('paidToSupplier') : t('notPaidToSupplier')),
        },
        {
            field: 'supplier',
            headerName: t('supplier'),
            sortable: false,
            flex: 0.8,
            valueFormatter: (_, row) => `${row.consignment?.supplier?.name}`,
        },
        {
            field: 'categories',
            headerName: t('categories'),
            flex: 1,
            valueFormatter: (value: ICategory[]) => value?.map(({ descriptionNl }) => descriptionNl)?.join(', '),
        },
        ...(filter.options?.includeReturns?.active
            ? [{ field: 'returnDate', headerName: t('returnDate'), sortable: false, width: 120 }]
            : []),
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            navigate(`${id}/detail`);
                        }}
                    >
                        <Search />
                    </IconButton>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/article/articles/${id}/edit`)}
                            onDelete={() => deleteArticle(id)}
                            deleteWarningTitle={t('articleDeleteWarningTitle')}
                            deleteWarningText={t('articleDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('articles')}
            filter={<ArticleFilter filter={filter} onChange={setFilter} />}
            actions={[
                <Tooltip title={t('downloadInventoryOverview')} arrow>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            const query = mapFiltersToQuery(filters);
                            window.open(`/dvc-auction-api/articles/inventory${query ? `?${query}` : ''}`, '__blank');
                        }}
                    >
                        <CloudDownloadTwoTone color="primary" sx={{ fontSize: '1.1em' }} />
                    </IconButton>
                </Tooltip>,
                <SearchField
                    search={search}
                    onSearch={setSearch}
                    label={t('searchOnArticleNumber')}
                    sx={{ minWidth: '150px' }}
                />,
                hasWritePermission && (
                    <Button
                        component={Link}
                        to="/article/articles/new"
                        color="primary"
                        variant="contained"
                        sx={{ minWidth: '150px' }}
                    >
                        {t('newArticle')}
                    </Button>
                ),
            ]}
        >
            <TableComponent
                rows={data?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={data?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                onRowClick={(row) => navigate(`/article/articles/${row.id}/detail`)}
            />
        </Page>
    );
};

function mapFiltersToQuery(filters: Partial<IArticleQuery>) {
    return Object.entries(filters)
        ?.filter((filter) => !!filter[1])
        ?.map((filter) =>
            typeof filter[1] === 'object'
                ? Object.entries(filter[1])
                      ?.map((entry) => `${filter[0]}=${entry[1]}`)
                      ?.join('&')
                : `${filter[0]}=${filter[1]}`,
        )
        ?.join('&');
}
