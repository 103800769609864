import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FC, Fragment, MouseEventHandler, ReactNode, useMemo } from 'react';
import { Loading } from '../loading/loading.component';
import { useBreakpoint } from '../../hooks';
import { FormGrid } from '../form-grid/form-grid.component';

interface Props {
    children: ReactNode;
    title?: ReactNode;
    filter?: ReactNode;
    subTitle?: ReactNode;
    actions?: ReactNode | ReactNode[];
    onBack?: MouseEventHandler;
    loading?: boolean;
}

export const Page: FC<Props> = ({ actions, onBack, title, subTitle, filter, children, loading }) => {
    const mappedActions = useMemo(() => (Array.isArray(actions) ? actions : [actions]), [actions]);
    const { palette } = useTheme();

    return (
        <Box sx={{ mb: useBreakpoint('sm') ? '0' : '75px' }}>
            {!title && <Box sx={{ p: 2 }}>{loading ? <Loading /> : children}</Box>}

            {title && (
                <Box>
                    <Stack
                        direction="row"
                        minWidth="inherit"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                            p: 2,
                            pb: 1,
                            minWidth: '100%',
                            minHeight: 96,
                            zIndex: 2,
                            backgroundColor: palette.background.paper,
                            borderBottom: '1px solid lightgrey',
                        }}
                    >
                        <FormGrid containerProps={{ spacing: 1, alignItems: 'center', flexWrap: 'nowrap' }}>
                            {onBack && (
                                <IconButton
                                    color="inherit"
                                    aria-label="back button"
                                    onClick={onBack}
                                    size="large"
                                    sx={{ color: 'text.secondary' }}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            )}

                            <Typography variant="subtitle1" mt={2} color="text.secondary">
                                {title}
                            </Typography>

                            {subTitle && (
                                <Typography variant="subtitle2" color="text.secondary">
                                    {subTitle}
                                </Typography>
                            )}

                            {filter}
                        </FormGrid>

                        {mappedActions && (
                            <FormGrid containerProps={{ spacing: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
                                {mappedActions.map((action, index) => (
                                    <Fragment key={index}>{action}</Fragment>
                                ))}
                            </FormGrid>
                        )}
                    </Stack>

                    <Box sx={{ p: 2 }}>{loading ? <Loading /> : children}</Box>
                </Box>
            )}
        </Box>
    );
};
