import { Payment, ReceiptLong } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateString, ISupplier, TableComponent, useQueryParams, Widget } from '../../../shared';
import { useSupplierPaymentsList } from '../../hooks/supplier-payment.hook';
import { RegisterSupplierPayments } from '../register-supplier-payments/register-supplier-payments.component';

interface Props {
    supplier: ISupplier;
}

export const PaymentsToSupplier: FC<Props> = ({ supplier }) => {
    const { t } = useTranslation();

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams();

    const { data: payments, isPending } = useSupplierPaymentsList({
        supplierId: supplier.id,
        page: page,
        pageSize: pageSize,
        sortBy: 'createdDate',
        sortOrder: 'DESC',
    });

    const columns: GridColDef[] = [
        { field: 'createdDate', headerName: t('createdDate'), flex: 1, valueFormatter: formatDateString },
        {
            field: ' ',
            headerName: '',
            sortable: false,
            renderCell: (row) => (
                <IconButton onClick={() => window.open(`/dvc-auction-api/supplier-payments/${row.id}/download`)}>
                    <ReceiptLong />
                </IconButton>
            ),
        },
    ];

    return (
        <Widget
            title={t('payments')}
            hasTable
            icon={<Payment />}
            actions={<RegisterSupplierPayments supplierId={supplier.id} />}
        >
            <TableComponent
                rows={payments?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={payments?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
            />
        </Widget>
    );
};
