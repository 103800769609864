import { IOrigins } from '../models';

//REMEMBER TO UPDATE THE NATIONALITIES UTIL AS WELL
export const getCountries: IOrigins[] = [
    { code: 'ad', label: 'Andorra' },
    { code: 'ae', label: 'Verenigde Arabische Emiraten' },
    { code: 'af', label: 'Afghanistan' },
    { code: 'ag', label: 'Antigua en Barbuda' },
    { code: 'ai', label: 'Anguilla' },
    { code: 'al', label: 'Albanië' },
    { code: 'am', label: 'Armenië' },
    { code: 'ao', label: 'Angola' },
    { code: 'aq', label: 'Antarctica' },
    { code: 'ar', label: 'Argentinië' },
    { code: 'at', label: 'Oostenrijk' },
    { code: 'au', label: 'Australië' },
    { code: 'aw', label: 'Aruba' },
    { code: 'az', label: 'Azerbekeyzjan' },
    { code: 'ba', label: 'Bosnië en Herzegovina' },
    { code: 'bb', label: 'Barbados' },
    { code: 'bd', label: 'Bangladesh' },
    { code: 'be', label: 'België' },
    { code: 'bf', label: 'Burkina Faso' },
    { code: 'bg', label: 'Bulgarije' },
    { code: 'bh', label: 'Bahrein' },
    { code: 'bi', label: 'Burundi' },
    { code: 'bj', label: 'Benin' },
    { code: 'bl', label: 'Saint Barthélemy' },
    { code: 'bm', label: 'Bermuda' },
    { code: 'bn', label: 'Brunei' },
    { code: 'bo', label: 'Bolivia' },
    { code: 'bq', label: 'Bonaire, Sint Eustatius en Saba' },
    { code: 'br', label: 'Brazilië' },
    { code: 'bs', label: "Bahama's" },
    { code: 'bt', label: 'Bhutan' },
    { code: 'bv', label: 'Bouveteiland' },
    { code: 'bw', label: 'Botswana' },
    { code: 'by', label: 'Wit-Rusland' },
    { code: 'bz', label: 'Belize' },
    { code: 'ca', label: 'Canada' },
    { code: 'cc', label: 'Cocoseilanden' },
    { code: 'cd', label: 'Congo' },
    { code: 'cf', label: 'Centraal-Afrikaanse Republiek' },
    { code: 'cg', label: 'Congo-Brazzaville' },
    { code: 'ch', label: 'Zwitserland' },
    { code: 'ci', label: 'Ivoorkust' },
    { code: 'ck', label: 'Cookeilanden' },
    { code: 'cl', label: 'Chili' },
    { code: 'cm', label: 'Kameroen' },
    { code: 'cn', label: 'China' },
    { code: 'co', label: 'Colombia' },
    { code: 'cr', label: 'Costa Rica' },
    { code: 'cu', label: 'Cuba' },
    { code: 'cv', label: 'Kaapverdië' },
    { code: 'cw', label: 'Curaçao' },
    { code: 'cx', label: 'Christmaseiland' },
    { code: 'cy', label: 'Cyprus' },
    { code: 'cz', label: 'Tsjechië' },
    { code: 'de', label: 'Duitsland' },
    { code: 'dj', label: 'Djibouti' },
    { code: 'dk', label: 'Denemarken' },
    { code: 'dm', label: 'Dominica' },
    { code: 'do', label: 'Dominicaanse Republiek' },
    { code: 'dz', label: 'Algerije' },
    { code: 'ec', label: 'Ecuador' },
    { code: 'ee', label: 'Estland' },
    { code: 'eg', label: 'Egypte' },
    { code: 'eh', label: 'Westelijke Sahara' },
    { code: 'er', label: 'Eritrea' },
    { code: 'es', label: 'Spanje' },
    { code: 'et', label: 'Ethiopië' },
    { code: 'fi', label: 'Finland' },
    { code: 'fj', label: 'Fiji' },
    { code: 'fk', label: 'Falklandeilanden' },
    { code: 'fm', label: 'Micronesia' },
    { code: 'fo', label: 'Faeröer' },
    { code: 'fr', label: 'Frankrijk' },
    { code: 'ga', label: 'Gabon' },
    { code: 'gb', label: 'Verenigd Koninkrijk' },
    { code: 'gd', label: 'Grenada' },
    { code: 'ge', label: 'Georgië' },
    { code: 'gf', label: 'Frans-Guyana' },
    { code: 'gg', label: 'Guernsey' },
    { code: 'gh', label: 'Ghana' },
    { code: 'gi', label: 'Gibraltar' },
    { code: 'gl', label: 'Groenland' },
    { code: 'gm', label: 'Gambia' },
    { code: 'gn', label: 'Guinee' },
    { code: 'gp', label: 'Guadeloupe' },
    { code: 'gq', label: 'Equatoriaal-Guinea' },
    { code: 'gr', label: 'Griekenland' },
    { code: 'gs', label: 'Zuid-Georgia en de Zukeyelijke Sandwicheilanden' },
    { code: 'gt', label: 'Guatemala' },
    { code: 'gu', label: 'Guam' },
    { code: 'gw', label: 'Guinee-Bissau' },
    { code: 'gy', label: 'Guyana' },
    { code: 'hk', label: 'Hongkong' },
    { code: 'hm', label: 'Heard en McDonaldeilanden' },
    { code: 'hn', label: 'Honduras' },
    { code: 'hr', label: 'Kroatië' },
    { code: 'ht', label: 'Haïti' },
    { code: 'hu', label: 'Hongarije' },
    { code: 'id', label: 'Indonesië' },
    { code: 'ie', label: 'Ierland' },
    { code: 'il', label: 'Israël' },
    { code: 'im', label: 'Man Eiland' },
    { code: 'in', label: 'India' },
    { code: 'io', label: 'Brits Indische Oceaanterritorium' },
    { code: 'iq', label: 'Irak' },
    { code: 'ir', label: 'Iran' },
    { code: 'is', label: 'IJsland' },
    { code: 'it', label: 'Italië' },
    { code: 'je', label: 'Jersey' },
    { code: 'jm', label: 'Jamaica' },
    { code: 'jo', label: 'Jordanië' },
    { code: 'jp', label: 'Japan' },
    { code: 'ke', label: 'Kenia' },
    { code: 'kg', label: 'Kirgizië' },
    { code: 'kh', label: 'Cambodja' },
    { code: 'ki', label: 'Kiribati' },
    { code: 'km', label: 'Comoren' },
    { code: 'kn', label: 'Saint Kitts en Nevis' },
    { code: 'kp', label: 'Noord-Korea' },
    { code: 'kr', label: 'Zuid-Korea' },
    { code: 'kw', label: 'Koeweit' },
    { code: 'ky', label: 'Kaaimaneilanden' },
    { code: 'kz', label: 'Kazachstan' },
    { code: 'la', label: 'Laos' },
    { code: 'lb', label: 'Libanon' },
    { code: 'lc', label: 'Saint Lucia' },
    { code: 'li', label: 'Liechtenstein' },
    { code: 'lk', label: 'Sri Lanka' },
    { code: 'lr', label: 'Liberia' },
    { code: 'ls', label: 'Lesotho' },
    { code: 'lt', label: 'Litouwen' },
    { code: 'lu', label: 'Luxemburg' },
    { code: 'lv', label: 'Letland' },
    { code: 'ly', label: 'Libië' },
    { code: 'ma', label: 'Marokko' },
    { code: 'mc', label: 'Monaco' },
    { code: 'md', label: 'Moldavië' },
    { code: 'me', label: 'Montenegro' },
    { code: 'mf', label: 'Sint-Maarten (Frans deel)' },
    { code: 'mg', label: 'Madagaskar' },
    { code: 'mh', label: 'Marshalleilanden' },
    { code: 'mk', label: 'Macedonië' },
    { code: 'ml', label: 'Mali' },
    { code: 'mm', label: 'Myanmar' },
    { code: 'mn', label: 'Mongolië' },
    { code: 'mo', label: 'Macau' },
    { code: 'mp', label: 'Noordelijke Marianen' },
    { code: 'mq', label: 'Martinique' },
    { code: 'mr', label: 'Mauritanië' },
    { code: 'ms', label: 'Montserrat' },
    { code: 'mt', label: 'Malta' },
    { code: 'mu', label: 'Mauritius' },
    { code: 'mv', label: 'Maldiven' },
    { code: 'mw', label: 'Malawi' },
    { code: 'mx', label: 'Mexico' },
    { code: 'my', label: 'Maleisië' },
    { code: 'mz', label: 'Mozambique' },
    { code: 'na', label: 'Namibië' },
    { code: 'nc', label: 'Nieuw-Caledonië' },
    { code: 'ne', label: 'Niger' },
    { code: 'nf', label: 'Norfolk' },
    { code: 'ng', label: 'Nigeria' },
    { code: 'ni', label: 'Nicaragua' },
    { code: 'nl', label: 'Nederland' },
    { code: 'no', label: 'Noorwegen' },
    { code: 'np', label: 'Nepal' },
    { code: 'nr', label: 'Nauru' },
    { code: 'nu', label: 'Niue' },
    { code: 'nz', label: 'Nieuw-Zeeland' },
    { code: 'om', label: 'Oman' },
    { code: 'pa', label: 'Panama' },
    { code: 'pe', label: 'Peru' },
    { code: 'pf', label: 'Frans-Polynesië' },
    { code: 'pg', label: 'Papoea-Nieuw-Guinea' },
    { code: 'ph', label: 'Filipijnen' },
    { code: 'pk', label: 'Pakistan' },
    { code: 'pl', label: 'Polen' },
    { code: 'pm', label: 'Saint-Pierre en Miquelon' },
    { code: 'pn', label: 'Pitcairn' },
    { code: 'pr', label: 'Puerto Rico' },
    { code: 'ps', label: 'Palestina' },
    { code: 'pt', label: 'Portugal' },
    { code: 'pw', label: 'Palau' },
    { code: 'py', label: 'Paraguay' },
    { code: 'qa', label: 'Qatar' },
    { code: 're', label: 'Réunion' },
    { code: 'ro', label: 'Roemenië' },
    { code: 'rs', label: 'Servië' },
    { code: 'ru', label: 'Rusland' },
    { code: 'rw', label: 'Rwanda' },
    { code: 'sa', label: 'Saoedi-Arabië' },
    { code: 'sb', label: 'Salomonseilanden' },
    { code: 'sc', label: 'Seychellen' },
    { code: 'sd', label: 'Soedan' },
    { code: 'se', label: 'Zweden' },
    { code: 'sg', label: 'Singapore' },
    { code: 'sh', label: 'Sint-Helena, Ascension en Tristan da Cunha' },
    { code: 'si', label: 'Slovenië' },
    { code: 'sj', label: 'Spitsbergen en Jan Mayen' },
    { code: 'sk', label: 'Slowakije' },
    { code: 'sl', label: 'Sierra Leone' },
    { code: 'sm', label: 'San Marino' },
    { code: 'sn', label: 'Senegal' },
    { code: 'so', label: 'Somalië' },
    { code: 'sr', label: 'Surilabel' },
    { code: 'ss', label: 'Zuid-Soedan' },
    { code: 'st', label: 'Sao Tomé en Principe' },
    { code: 'sv', label: 'El Salvador' },
    { code: 'sx', label: 'Sint Maarten (Nederlands deel)' },
    { code: 'sy', label: 'Syrië' },
    { code: 'sz', label: 'Swaziland' },
    { code: 'tc', label: 'Turks- en Caicoseilanden' },
    { code: 'td', label: 'Tsjaad' },
    { code: 'tf', label: 'Franse Zukeyelijke en Antarctische Gebieden' },
    { code: 'tg', label: 'Togo' },
    { code: 'th', label: 'Thailand' },
    { code: 'tj', label: 'Tadzjikistan' },
    { code: 'tk', label: 'Tokelau' },
    { code: 'tl', label: 'Oost-Timor' },
    { code: 'tm', label: 'Turkmenistan' },
    { code: 'tn', label: 'Tunesië' },
    { code: 'to', label: 'Tonga' },
    { code: 'tr', label: 'Turkije' },
    { code: 'tt', label: 'Trinkeyad en Tobago' },
    { code: 'tv', label: 'Tuvalu' },
    { code: 'tw', label: 'Taiwan' },
    { code: 'tz', label: 'Tanzania' },
    { code: 'ua', label: 'Oekraïne' },
    { code: 'ug', label: 'Oeganda' },
    { code: 'um', label: 'Kleine afgelegen eilanden van de Verenigde Staten' },
    { code: 'us', label: 'Verenigde Staten' },
    { code: 'uy', label: 'Uruguay' },
    { code: 'uz', label: 'Oezbekistan' },
    { code: 'va', label: 'Vaticaanstad' },
    { code: 'vc', label: 'Saint Vincent en de Grenadines' },
    { code: 've', label: 'Venezuela' },
    { code: 'vg', label: 'Britse Maagdeneilanden' },
    { code: 'vi', label: 'Amerikaanse Maagdeneilanden' },
    { code: 'vn', label: 'Vietnam' },
    { code: 'vu', label: 'Vanuatu' },
    { code: 'wf', label: 'Wallis en Futuna' },
    { code: 'ws', label: 'Samoa' },
    { code: 'xk', label: 'Kosovo' },
    { code: 'ye', label: 'Jemen' },
    { code: 'yt', label: 'Mayotte' },
    { code: 'za', label: 'Zuid-Afrika' },
    { code: 'zm', label: 'Zambia' },
    { code: 'zw', label: 'Zimbabwe' },
];
