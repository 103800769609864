import { Box, InputAdornment, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { FC, KeyboardEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledNumberInput, Widget } from '../../../shared';
import { AxiosError } from 'axios';
import { useUniqueCheckLotForAuction } from '../../hooks';
import { useFormContext, useWatch } from 'react-hook-form';

interface Props {
    auctionId: string;
    lotId?: string;
}

export const LotInputsGeneral: FC<Props> = ({ auctionId, lotId }) => {
    const { t } = useTranslation();
    const { mutateAsync: uniqueLotCheck } = useUniqueCheckLotForAuction();

    const { getValues } = useFormContext();
    const { setError, formState, trigger } = useFormContext();
    const watchArticles = useWatch({ name: 'articles' });
    const watchNumber = useWatch({ name: 'lotNumber' });
    const watchSuffix = useWatch({ name: 'lotNumberSuffix' });

    const uniqueCheck = useCallback(async () => {
        try {
            await uniqueLotCheck({
                auctionId,
                lotNumber: Number(watchNumber),
                lotNumberSuffix: watchSuffix || null,
                lotId,
            });
            await trigger(['lotNumber', 'lotNumberSuffix']);
        } catch (err: unknown) {
            if ((err as AxiosError)?.response?.status === 409) {
                setError('lotNumber', { type: 'value' });
                setError('lotNumberSuffix', { type: 'value' });
                return;
            }
            throw err;
        }
    }, [uniqueLotCheck, auctionId, watchNumber, watchSuffix, lotId, setError, trigger]);

    const handleOnTab = useCallback(
        (event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Tab') {
                event.preventDefault();
                const index = (getValues('articles')?.length - 1).toString();
                window.document.getElementById(index)?.focus();
            }
        },
        [getValues],
    );

    return (
        <Grid size={{ xs: 12, md: 3 }}>
            <Widget
                title={t('lotInfo')}
                sx={{ '.MuiStack-root': { borderBottom: 0 }, '.MuiTextField-root': { minWidth: 'inherit' } }}
            >
                <Stack spacing={2}>
                    <Box>
                        <Stack direction="row" spacing={2}>
                            <ControlledNumberInput
                                name="lotNumber"
                                label={t('lotNumber')}
                                required
                                decimalScale={0}
                                autoFocus
                            />
                            <ControlledInput
                                name="lotNumberSuffix"
                                label={t('lotNumberSuffix')}
                                onBlur={() => uniqueCheck()}
                            />
                        </Stack>

                        {!!formState.errors?.lotNumber && formState.errors?.lotNumberSuffix && (
                            <Typography variant="caption" color="error" pl={1}>
                                {t('uniqueLotNumberWarning')}
                            </Typography>
                        )}
                    </Box>

                    <Stack direction="row" spacing={2}>
                        <ControlledNumberInput
                            name="minEstimate"
                            label={t('minEstimate')}
                            slotProps={{ input: { startAdornment: <InputAdornment position="start" children="€" /> } }}
                            sx={{ input: { pl: 1 } }}
                        />
                        <ControlledNumberInput
                            name="maxEstimate"
                            label={t('maxEstimate')}
                            slotProps={{ input: { startAdornment: <InputAdornment position="start" children="€" /> } }}
                            sx={{ input: { pl: 1 } }}
                            onKeyDown={(event) => {
                                if (watchArticles?.length <= 1) handleOnTab(event);
                            }}
                        />
                    </Stack>

                    {watchArticles?.length > 1 && (
                        <>
                            <ControlledInput
                                name="descriptionNl"
                                label={t('descriptionNl')}
                                required
                                multiline
                                rows={8}
                            />
                            <ControlledInput
                                name="descriptionEn"
                                label={t('descriptionEn')}
                                required
                                multiline
                                rows={8}
                                onKeyDown={(event) => {
                                    if (watchArticles?.length > 1) handleOnTab(event);
                                }}
                            />
                        </>
                    )}
                </Stack>
            </Widget>
        </Grid>
    );
};
