import {
    auctionApi,
    BaseClient,
    IAuctioningLot,
    ICreditLot,
    ILot,
    ILotForSignature,
    ISaveLot,
    ISaveLotStatus,
    ISaveSignature,
    IUniqueLotCheck,
} from '../../shared';

class LotClient extends BaseClient<ILot, ISaveLot> {
    constructor() {
        super(auctionApi, '/lots');
    }

    public async auctioningLot(id: string, item: IAuctioningLot): Promise<void> {
        await this.api.put<void>(`${this.basePath}/${id}/sale`, item);
    }

    public async setSetLotStatus(saveLotStatus: ISaveLotStatus): Promise<void> {
        await auctionApi.post('/lots/status', saveLotStatus);
    }

    public async creditLots(item: ICreditLot): Promise<void> {
        await auctionApi.post('/lots/credit', item);
    }

    public async saveSignature(signature: ISaveSignature): Promise<void> {
        await auctionApi.post(`/lots/${signature.lotId}/signature`, signature);
    }

    public async uniqueCheckLotForAuction(item: IUniqueLotCheck): Promise<void> {
        await auctionApi.post(`/lots/unique-check`, item);
    }

    public async getLotsForSignature(auctionId: string): Promise<ILotForSignature[]> {
        const { data } = await auctionApi.get<ILotForSignature[]>(`${this.basePath}/signatures/${auctionId}`);
        return data;
    }
}

export const lotClient = new LotClient();
