import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, FormGrid, ILot, ILotForSignature, LabelValue, Widget } from '../../../shared';

interface Props {
    currentLot: ILotForSignature;
}

export const LotInfo: FC<Props> = ({ currentLot }) => {
    const { t } = useTranslation();

    return (
        <Widget title={t('lot')} sx={{ width: '100%' }}>
            <FormGrid size={{ xs: 12, sm: 6 }} containerProps={{ spacing: 0 }}>
                <LabelValue
                    label={t('lotNumber')}
                    value={`${currentLot.lotNumber}${currentLot.lotNumberSuffix || ''}`}
                />
                <LabelValue label={t('hammerPrice')} value={formatPrice(currentLot.hammerPrice)} />
                <LabelValue label={t('description')} value={currentLot.descriptionNl} sx={{ whiteSpace: 'pre-line' }} />
            </FormGrid>
        </Widget>
    );
};
