import { CloudDownloadTwoTone } from '@mui/icons-material';
import { Button, IconButton, Stack } from '@mui/material';
import { GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Page, PermissionKeys, SortOrder, TableComponent, useHasPermission, useQueryParams } from '../../../shared';
import { usePurchaseRegistersList } from '../../hooks';

export const PurchaseRegistersPage: FC = () => {
    const { t } = useTranslation();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SUPPLIERS_WRITE);

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'date', sort: 'desc' as GridSortDirection }],
    });

    const { data: registers, isPending } = usePurchaseRegistersList({
        page,
        pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
    });

    const columns: GridColDef[] = [
        {
            field: ' ',
            headerName: '',
            width: 60,
            renderCell: () => (
                <IconButton>
                    <CloudDownloadTwoTone color="primary" />
                </IconButton>
            ),
        },
        {
            field: 'date',
            headerName: t('date'),
            flex: 1,
            valueFormatter: (value) => format(new Date(value), 'yyyy MMMM'),
        },
    ];

    return (
        <Page
            title={t('purchaseRegisters')}
            actions={[
                hasWritePermission && (
                    <Stack direction="row" spacing={2}>
                        <Button
                            onClick={() => window.open(`/dvc-auction-api/purchase-registers/current-stock`, '__blank')}
                            color="primary"
                            variant="contained"
                        >
                            {t('currentStock')}
                        </Button>
                        <Button component={Link} to="/purchase-registers/new" color="primary" variant="contained">
                            {t('newPurchaseRegister')}
                        </Button>
                    </Stack>
                ),
            ]}
        >
            <TableComponent
                rows={registers?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={registers?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                onRowClick={({ row }) =>
                    window.open(`/dvc-auction-api/purchase-registers/download/${row.assetId}`, '__blank')
                }
            />
        </Page>
    );
};
