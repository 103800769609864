import { Stack } from '@mui/material';
import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledInput } from '../../../shared';
import { useAbbreviationsMap } from '../../../admin/hooks';
import { descriptionReplacementUtil } from '../../../article/utils';

interface Props {
    index: number;
}

export const LotInputsArticleDescription: FC<Props> = ({ index }) => {
    const { t } = useTranslation();

    const { setValue, getValues } = useFormContext();
    const watchShortDescription = useWatch({ name: `articles.${index}.shortDescription` });
    const abbreviationsMap = useAbbreviationsMap();

    const onBlurShortDescription = () => {
        if (watchShortDescription) {
            if (!getValues(`articles.${index}.descriptionNl`)) {
                setValue(
                    `articles.${index}.descriptionNl`,
                    descriptionReplacementUtil(watchShortDescription, 'nl', abbreviationsMap),
                );
            }
            if (!getValues(`articles.${index}.descriptionEn`)) {
                setValue(
                    `articles.${index}.descriptionEn`,
                    descriptionReplacementUtil(watchShortDescription, 'en', abbreviationsMap),
                );
            }
        }
    };

    return (
        <Stack spacing={2}>
            <ControlledInput
                name={`articles.${index}.shortDescription`}
                label={t('shortDescription')}
                multiline
                rows={2}
                onBlur={() => onBlurShortDescription()}
            />
            <ControlledInput
                name={`articles.${index}.descriptionNl`}
                label={t('descriptionNl')}
                multiline
                rows={4}
                required
            />
            <ControlledInput
                name={`articles.${index}.descriptionEn`}
                label={t('descriptionEn')}
                multiline
                rows={4}
                required
            />
        </Stack>
    );
};
