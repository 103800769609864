import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ILotForm, IParams, Page } from '../../../shared';
import { useLot, useSaveLot } from '../../hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLotSchema } from '../../validators';
import { getLotFormDefaults, lotFromFormMapper, lotToFormMapper } from '../../mappers';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import { LotInputs } from '../../components/lot-inputs/lot-inputs.component';

export const LotEditPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();

    const { state: auction } = useLocation();
    const { data: lot, isLoading } = useLot(id);
    const { mutateAsync: saveLot, isPending: isSaving, isSuccess, reset: resetSaveLot } = useSaveLot();

    const form = useForm<ILotForm>({
        resolver: yupResolver(useLotSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (lot) return form.reset(lotToFormMapper(lot));
        form.reset(getLotFormDefaults());
    }, [form, lot]);

    const onSubmit = useCallback(
        async (item: ILotForm, next = false) => {
            try {
                await saveLot({ id: lot?.id, item: lotFromFormMapper(item, auction.id) });
                if (next) {
                    form.reset(getLotFormDefaults());
                    resetSaveLot();
                    if (id) return navigate(`/auction/auctions/lot/new`, { state: auction });
                    window.document.getElementById('lotNumber-field')?.focus();
                    return;
                }
                return navigate(`/auction/auctions/${auction.id}/detail`);
            } catch (err: unknown) {
                if ((err as AxiosError)?.response?.status === 409) {
                    form.setError('lotNumber', { type: 'value' });
                    form.setError('lotNumberSuffix', { type: 'value' });
                    return;
                }
                throw err;
            }
        },
        [auction, form, lot?.id, navigate, saveLot],
    );

    const onBack = useCallback(async () => {
        return navigate(`/auction/auctions/${auction.id}/detail`);
    }, [navigate, auction.id]);

    return (
        <FormProvider {...form}>
            <Page
                title={`${lot ? `${t('lot')} - ${lot?.lotNumber}${lot?.lotNumberSuffix ?? ''}` : `${t('newLot')}`}`}
                onBack={onBack}
                loading={isLoading || isSaving || isSuccess}
                actions={[
                    <Button
                        onClick={() => navigate(`/auction/auctions/${auction.id}/detail`)}
                        color="secondary"
                        disabled={isSaving}
                    >
                        {t('cancel')}
                    </Button>,

                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={isSaving}
                        onClick={form.handleSubmit((values) => onSubmit(values))}
                    >
                        {t('save')}
                    </Button>,
                    <Button
                        id="save-next"
                        variant="contained"
                        color="primary"
                        disabled={isSaving}
                        onClick={form.handleSubmit((values) => onSubmit(values, true))}
                    >
                        {t('saveAndNext')}
                    </Button>,
                ]}
            >
                <LotInputs auctionId={auction.id} lot={lot} />
            </Page>
        </FormProvider>
    );
};
