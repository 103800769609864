import { PriorityHigh } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IArticle, ILot, LotStatus } from '../../../shared';
import { useLotsList } from '../../hooks';

interface Props {
    index: number;
    lot?: ILot;
    auctionId?: string;
    setInUseWarning: (inUseWarning: boolean) => void;
    articles: IArticle[];
}

export const ArticleInUseWarning: FC<Props> = ({ index, lot, auctionId, setInUseWarning, articles }) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const selectedArticle = watch(`articles.${index}`) as IArticle;

    const { data: lots } = useLotsList({ articleId: selectedArticle.id }, { enabled: !!selectedArticle.id });

    const linkedLots = useMemo(() => {
        if (!selectedArticle) return [];
        const filteredLots =
            lots?.data?.filter(({ id, status }) => status !== LotStatus.NOT_SOLD && id !== lot?.id) || [];

        const showInUseWarning =
            !lot?.articles?.find((article) => article?.id === selectedArticle?.id) &&
            filteredLots?.some((lot) => lot.auction.id !== auctionId);

        if (selectedArticle && filteredLots.length > 0) {
            if (showInUseWarning) setInUseWarning(true);
            return filteredLots
                ?.map(({ lotNumber, lotNumberSuffix }) => `${lotNumber}${lotNumberSuffix || ''}`)
                .join(', ');
        }

        return [];
    }, [articles, setInUseWarning, auctionId, lot, selectedArticle, lots]);

    if (linkedLots.length > 0) {
        return (
            <Tooltip
                title={
                    <Typography sx={{ fontSize: 14 }}>
                        {t('articleUsedWithinLot', { count: linkedLots.length, lot: linkedLots })}
                    </Typography>
                }
                arrow
            >
                <PriorityHigh sx={{ fontSize: 20 }} color="warning" />
            </Tooltip>
        );
    }
};
