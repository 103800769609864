import { IOrigins } from '../models';

//REMEMBER TO UPDATE THE COUNTRIES UTIL AS WELL
export const getNationalities: IOrigins[] = [
    { code: 'ad', label: 'Andorrees' },
    { code: 'ae', label: 'Emirisch' },
    { code: 'af', label: 'Afghaan' },
    { code: 'ag', label: 'Antiguanen' },
    { code: 'ai', label: "Anguilla's" },
    { code: 'al', label: 'Albanees' },
    { code: 'am', label: 'Armeens' },
    { code: 'ao', label: 'Angolees' },
    { code: 'aq', label: 'Inuit' },
    { code: 'ar', label: 'Argentijn' },
    { code: 'at', label: 'Oostenrijks' },
    { code: 'au', label: 'Australisch' },
    { code: 'aw', label: 'Arubanen' },
    { code: 'az', label: 'Azerbeidzjaans' },
    { code: 'ba', label: 'Bosnisch - Herzegovina' },
    { code: 'bb', label: 'Barbados' },
    { code: 'bd', label: 'Bengaal' },
    { code: 'be', label: 'Belg' },
    { code: 'bf', label: 'Burkinabe' },
    { code: 'bg', label: 'Bulgaar' },
    { code: 'bh', label: 'Bahreins' },
    { code: 'bi', label: 'Burundisch' },
    { code: 'bj', label: 'Beninees' },
    { code: 'bl', label: 'Saint-Barthélemy' },
    { code: 'bm', label: 'Bermuda' },
    { code: 'bn', label: 'Bruneiaans' },
    { code: 'bo', label: 'Boliviaan' },
    { code: 'bq', label: 'Bonaire, Sint Eustatius en Saba' },
    { code: 'br', label: 'Braziliaan' },
    { code: 'bs', label: 'Bahamiaans' },
    { code: 'bt', label: 'Bhutanees' },
    { code: 'bv', label: 'Bouvet-eiland' },
    { code: 'bw', label: 'Batswana' },
    { code: 'by', label: 'Wit-Russisch' },
    { code: 'bz', label: 'Belizaan' },
    { code: 'ca', label: 'Canadees' },
    { code: 'cc', label: 'Cocos (Keeling) Eilanden' },
    { code: 'cd', label: 'Congolees (Democratische Republiek Congo)' },
    { code: 'cf', label: 'Centraal-Afrikaan' },
    { code: 'cg', label: 'Congolees' },
    { code: 'ch', label: 'Zwitser' },
    { code: 'ci', label: 'Ivoriaan' },
    { code: 'ck', label: 'Cookeilanden' },
    { code: 'cl', label: 'Chileen' },
    { code: 'cm', label: 'Kameroener' },
    { code: 'cn', label: 'Chinees' },
    { code: 'co', label: 'Colombiaan' },
    { code: 'cr', label: 'Costa Ricaan' },
    { code: 'cu', label: 'Cubaan' },
    { code: 'cv', label: 'Kaapverdiaans' },
    { code: 'cw', label: 'Curaçao' },
    { code: 'cx', label: 'Kersteiland' },
    { code: 'cy', label: 'Cypriotische' },
    { code: 'cz', label: 'Tsjech' },
    { code: 'de', label: 'Duits' },
    { code: 'dj', label: 'Djiboutiaan' },
    { code: 'dk', label: 'Deen' },
    { code: 'dm', label: 'Dominicaanse (Dominica)' },
    { code: 'do', label: 'Dominicaan' },
    { code: 'dz', label: 'Algerijn' },
    { code: 'ec', label: 'Ecuadoraans' },
    { code: 'ee', label: 'Ests' },
    { code: 'eg', label: 'Egyptisch' },
    { code: 'eh', label: 'Westelijke Sahara' },
    { code: 'er', label: 'Eritrese' },
    { code: 'es', label: 'Spaans' },
    { code: 'et', label: 'Ethiopisch' },
    { code: 'fi', label: 'Fin' },
    { code: 'fj', label: 'Fijiaans' },
    { code: 'fk', label: 'Falklandeilanden (Malvinas)' },
    { code: 'fm', label: 'Micronesisch' },
    { code: 'fo', label: 'Faeröer Eilanden' },
    { code: 'fr', label: 'Frans' },
    { code: 'ga', label: 'Gabonees' },
    { code: 'gb', label: 'Brit' },
    { code: 'gd', label: 'Grenadiaans' },
    { code: 'ge', label: 'Georgisch' },
    { code: 'gf', label: 'Frans (Frans Guyana)' },
    { code: 'gg', label: 'Guernsey' },
    { code: 'gh', label: 'Ghanees' },
    { code: 'gi', label: 'Gibraltar' },
    { code: 'gl', label: 'Groenland' },
    { code: 'gm', label: 'Gambiaan' },
    { code: 'gn', label: 'Guinees' },
    { code: 'gp', label: 'Guadeloupe' },
    { code: 'gq', label: 'Equatoriaal Guinee' },
    { code: 'gr', label: 'Griek' },
    { code: 'gs', label: 'Zuid-Georgië en de Zuidelijke Sandwicheilanden' },
    { code: 'gt', label: 'Guatemalteeks' },
    { code: 'gu', label: 'Guam' },
    { code: 'gw', label: 'Guinee-Bissauan' },
    { code: 'gy', label: 'Guyanese' },
    { code: 'hk', label: 'Hongkong' },
    { code: 'hm', label: 'Heard-eiland en Mcdonald-eilanden' },
    { code: 'hn', label: 'Hondurese' },
    { code: 'hr', label: 'Kroatisch' },
    { code: 'ht', label: 'Haïtiaan' },
    { code: 'hu', label: 'Hongaar' },
    { code: 'id', label: 'Indonesisch' },
    { code: 'ie', label: 'Ier' },
    { code: 'il', label: 'Israëlisch' },
    { code: 'im', label: 'Manx' },
    { code: 'in', label: 'Indiaas' },
    { code: 'io', label: 'Brits Territorium in de Indische Oceaan' },
    { code: 'iq', label: 'Iraakse' },
    { code: 'ir', label: 'Iraans' },
    { code: 'is', label: 'IJslander' },
    { code: 'it', label: 'Italiaan' },
    { code: 'je', label: 'Jersey' },
    { code: 'jm', label: 'Jamaicaan' },
    { code: 'jo', label: 'Jordaans' },
    { code: 'jp', label: 'Japans' },
    { code: 'ke', label: 'Keniaans' },
    { code: 'kg', label: 'Kirgizisch' },
    { code: 'kh', label: 'Cambodjaan' },
    { code: 'ki', label: 'I-Kiribati' },
    { code: 'km', label: 'Comoraan' },
    { code: 'kn', label: 'Kittian en Nevisiaans' },
    { code: 'kp', label: 'Noord-Koreaans' },
    { code: 'kr', label: 'Zuid-Koreaan' },
    { code: 'kw', label: 'Koeweitse' },
    { code: 'ky', label: 'Kaaimaneilanden' },
    { code: 'kz', label: 'Kazachstaans' },
    { code: 'la', label: 'Laotiaan' },
    { code: 'lb', label: 'Libanees' },
    { code: 'lc', label: 'Saint Lucian' },
    { code: 'li', label: 'Liechtensteiner' },
    { code: 'lk', label: 'Sri Lankaan' },
    { code: 'lr', label: 'Liberiaan' },
    { code: 'ls', label: 'Mosotho' },
    { code: 'lt', label: 'Litouws' },
    { code: 'lu', label: 'Luxemburgs' },
    { code: 'lv', label: 'Lets' },
    { code: 'ly', label: 'Libisch' },
    { code: 'ma', label: 'Marokkaan' },
    { code: 'mc', label: 'Monacan' },
    { code: 'md', label: 'Moldavisch' },
    { code: 'me', label: 'Montenegrijn' },
    { code: 'mf', label: 'Saint Martin (Frans gedeelte)' },
    { code: 'mg', label: 'Malagasië' },
    { code: 'mh', label: 'Marshallees' },
    { code: 'mk', label: 'Macedonisch' },
    { code: 'ml', label: 'Malinees' },
    { code: 'mm', label: 'Birmaans' },
    { code: 'mn', label: 'Mongools' },
    { code: 'mo', label: 'Macau' },
    { code: 'mp', label: 'Noordelijke Marianen' },
    { code: 'mq', label: 'Martinique' },
    { code: 'mr', label: 'Mauritaans' },
    { code: 'ms', label: 'Montserrat' },
    { code: 'mt', label: 'Maltees' },
    { code: 'mu', label: 'Mauritiaan' },
    { code: 'mv', label: 'Maldivan' },
    { code: 'mw', label: 'Malawisch' },
    { code: 'mx', label: 'Mexicaan' },
    { code: 'my', label: 'Maleisisch' },
    { code: 'mz', label: 'Mozambikaan' },
    { code: 'na', label: 'Namibisch' },
    { code: 'nc', label: 'Nieuw-Caledonië' },
    { code: 'ne', label: 'Nigeriaanse' },
    { code: 'nf', label: 'Norfolkeiland' },
    { code: 'ng', label: 'Nigeriaan' },
    { code: 'ni', label: 'Nicaraguaan' },
    { code: 'nl', label: 'Nederlandse' },
    { code: 'no', label: 'Noor' },
    { code: 'np', label: 'Nepalees' },
    { code: 'nr', label: 'Nauruaans' },
    { code: 'nu', label: 'Neen' },
    { code: 'nz', label: 'Nieuw-Zeelander' },
    { code: 'om', label: 'Omani' },
    { code: 'pa', label: 'Panamese' },
    { code: 'pe', label: 'Peruviaans' },
    { code: 'pf', label: 'Frans (Frans-Polynesië)' },
    { code: 'pg', label: 'Papoea-Nieuw-Guinea' },
    { code: 'ph', label: 'Filipijns' },
    { code: 'pk', label: 'Pakistaans' },
    { code: 'pl', label: 'Pool' },
    { code: 'pm', label: 'Saint-Pierre en Miquelon' },
    { code: 'pn', label: 'Pitcairn' },
    { code: 'pr', label: 'Puerto Rico' },
    { code: 'ps', label: 'Palestijns grondgebied, bezet' },
    { code: 'pt', label: 'Portugees' },
    { code: 'pw', label: 'Palauan' },
    { code: 'py', label: 'Paraguayaan' },
    { code: 'qa', label: 'Qatari' },
    { code: 're', label: 'Reünie' },
    { code: 'ro', label: 'Roemeen' },
    { code: 'rs', label: 'Servisch' },
    { code: 'ru', label: 'Rus' },
    { code: 'rw', label: 'Rwandees' },
    { code: 'sa', label: 'Saoedi' },
    { code: 'sb', label: 'Solomon Islander' },
    { code: 'sc', label: 'Seychellen' },
    { code: 'sd', label: 'Soedanees' },
    { code: 'se', label: 'Zweed' },
    { code: 'sg', label: 'Singaporese' },
    { code: 'sh', label: 'Sint-Helena' },
    { code: 'si', label: 'Sloveen' },
    { code: 'sj', label: 'Svalbard en Jan Mayen' },
    { code: 'sk', label: 'Slowaak' },
    { code: 'sl', label: 'Sierra Leonean' },
    { code: 'sm', label: 'San Marinese' },
    { code: 'sn', label: 'Senegalees' },
    { code: 'so', label: 'Somalisch' },
    { code: 'sr', label: 'Surinaamse' },
    { code: 'ss', label: 'Soedanees' },
    { code: 'st', label: 'Sao Toméan' },
    { code: 'sv', label: 'Salvadoraan' },
    { code: 'sx', label: 'Sint Maarten (Nederlands deel)' },
    { code: 'sy', label: 'Syrisch' },
    { code: 'sz', label: 'Swazi' },
    { code: 'tc', label: 'Turken- en Caicoseilanden' },
    { code: 'td', label: 'Tsjaads' },
    { code: 'tf', label: 'Frans (Franse Zuidelijke Gebieden)' },
    { code: 'tg', label: 'Togolees' },
    { code: 'th', label: 'Thaise' },
    { code: 'tj', label: 'Tadzjieks' },
    { code: 'tk', label: 'Tokelau' },
    { code: 'tl', label: 'Oost-Timorees' },
    { code: 'tm', label: 'Turkmeen' },
    { code: 'tn', label: 'Tunesisch' },
    { code: 'to', label: 'Tongaan' },
    { code: 'tr', label: 'Turk' },
    { code: 'tt', label: 'Trinidadisch of Tobagonisch' },
    { code: 'tv', label: 'Tuvaluaans' },
    { code: 'tw', label: 'Taiwanees' },
    { code: 'tz', label: 'Tanzaniaan' },
    { code: 'ua', label: 'Oekraïens' },
    { code: 'ug', label: 'Oegandees' },
    { code: 'um', label: 'Verenigde Staten kleine afgelegen eilanden' },
    { code: 'us', label: 'Amerikaans' },
    { code: 'uy', label: 'Uruguayaan' },
    { code: 'uz', label: 'Oezbeekse' },
    { code: 'va', label: 'Heilige Stoel (Vaticaanstad)' },
    { code: 'vc', label: 'Saint Vincent en de Grenadines' },
    { code: 've', label: 'Venezolaan' },
    { code: 'vg', label: 'Maagdeneilanden, Brits' },
    { code: 'vi', label: 'Maagdeneilanden, V.S.' },
    { code: 'vn', label: 'Vietnamees' },
    { code: 'vu', label: 'Vanuatu' },
    { code: 'wf', label: 'Welsh' },
    { code: 'ws', label: 'Samoaan' },
    { code: 'xk', label: 'Kosovo' },
    { code: 'ye', label: 'Jemenitisch' },
    { code: 'yt', label: 'Mayotte' },
    { code: 'za', label: 'Zuid-Afrikaan' },
    { code: 'zm', label: 'Zambiaan' },
    { code: 'zw', label: 'Zimbabwaan' },
];
