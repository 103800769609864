import {
    IAuctionSlot,
    IAuctionSlotQuery,
    ISaveAuctionSlot,
    QueryKeys,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
} from '../../shared';
import { auctionSlotClient } from '../clients';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const client = auctionSlotClient;
const listKey = QueryKeys.AuctionSlots;
const itemKey = QueryKeys.AuctionSlot;

export function useAuctionSlotsList(...args: UseListParams<IAuctionSlot, IAuctionSlotQuery>) {
    return useList(client, listKey, ...args);
}

export function useAuctionSlot(...args: UseItemParams<IAuctionSlot>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveAuctionSlot(): UseMutationResult<
    IAuctionSlot,
    AxiosError,
    { item: ISaveAuctionSlot; id?: string }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ item, id }) => (id ? client.updateOne(id, item) : client.createOne(item)),
        onSuccess: async ({ id }, { item }) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AuctionSlots] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AuctionSlot, id] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AuctionMissingLots, item.auctionId] });
        },
    });
}

export function useDeleteAuctionSlot(): UseMutationResult<IAuctionSlot, AxiosError, string> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (auctionSlotId) => client.deleteOne(auctionSlotId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AuctionSlots] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Auction] });
        },
    });
}

export function useCloseAuctionSlot(): UseMutationResult<void, AxiosError, string> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (auctionSlotId) => client.closeAuctionSlot(auctionSlotId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AuctionSlots] });
        },
    });
}
