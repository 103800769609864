import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledSelect } from '../../../shared';
import { MenuItem, Stack } from '@mui/material';

interface Props {
    prefix?: string;
}

export const ArticleDimensionInputs: FC<Props> = ({ prefix = '' }) => {
    const { t } = useTranslation();

    return (
        <Stack spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <ControlledInput name={`${prefix}dimensions.width`} label={t('width')} sx={{ minWidth: 60 }} />
                <ControlledInput name={`${prefix}dimensions.height`} label={t('height')} sx={{ minWidth: 60 }} />
                <ControlledInput name={`${prefix}dimensions.depth`} label={t('depth')} sx={{ minWidth: 60 }} />
            </Stack>

            <Stack direction="row" alignItems="center" spacing={2}>
                <ControlledInput name={`${prefix}dimensions.length`} label={t('length')} sx={{ minWidth: 60 }} />
                <ControlledInput name={`${prefix}dimensions.diameter`} label={t('diameter')} sx={{ minWidth: 60 }} />
                <ControlledSelect
                    name={`${prefix}dimensions.measurementUnit`}
                    label={t('measurementUnit')}
                    sx={{ minWidth: 120, width: 120 }}
                >
                    <MenuItem value="mm">mm</MenuItem>
                    <MenuItem value="cm">cm</MenuItem>
                    <MenuItem value="m">m</MenuItem>
                </ControlledSelect>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={2}>
                <ControlledInput name={`${prefix}dimensions.weight`} label={t('weight')} sx={{ minWidth: 80 }} />

                <ControlledSelect
                    name={`${prefix}dimensions.weightUnit`}
                    label={t('weightUnit')}
                    sx={{ minWidth: 120, width: 120 }}
                >
                    <MenuItem value="gr">gr</MenuItem>
                    <MenuItem value="kg">kg</MenuItem>
                </ControlledSelect>
            </Stack>
        </Stack>
    );
};
