import { articleDimensionsToFormMapper } from '../../article/mappers';
import { ILot, ILotForm, ISaveLot } from '../../shared';
import { getLotArticleDefaults } from './lot-article.mapper';

export function getLotFormDefaults(): Required<Omit<ILotForm, 'status'>> {
    return {
        lotNumber: '',
        lotNumberSuffix: '',
        minEstimate: '',
        maxEstimate: '',
        descriptionNl: '',
        descriptionEn: '',
        articles: [getLotArticleDefaults()],
    };
}

export const lotToFormMapper = (item: ILot): ILotForm => {
    return {
        ...item,
        articles: item.articles?.map((article) => ({
            ...article,
            categories: article.categories?.map((cat) => ({ ...cat, label: cat.descriptionNl })) || [],
            dimensions: articleDimensionsToFormMapper(article.dimensions),
            percentage: item.articlePercentages?.find(({ id }) => id === article.id)?.percentage || 0,
        })),
        lotNumberSuffix: item.lotNumberSuffix || '',
    };
};

export const lotFromFormMapper = (item: ILotForm, auctionId: string): ISaveLot => {
    return {
        ...item,
        lotNumber: Number(item.lotNumber),
        articles: item.articles?.map((article) => ({
            ...article,
            articleId: article?.id,
            artistId: article.artist?.id || undefined,
            categoryIds: article.categories?.map(({ id }) => id),
        })),
        articlePercentages: item.articles?.map(({ id, percentage }) => ({
            id,
            percentage: parseFloat(Number(percentage)?.toFixed(2)) || 0,
        })),
        auctionId,
        lotNumberSuffix: item.lotNumberSuffix || null,
        minEstimate: item.minEstimate ? Number(item.minEstimate) : null,
        maxEstimate: item.maxEstimate ? Number(item.maxEstimate) : null,
        descriptionNl: mapDescription(item, 'descriptionNl'),
        descriptionEn: mapDescription(item, 'descriptionEn'),
    };
};

function mapDescription(item: ILotForm, attribute: 'descriptionNl' | 'descriptionEn') {
    if (item.articles?.length > 1) return item[attribute];
    return item.articles[0]?.[attribute];
}
