import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useArticlesAvailableForPurchaseRegister } from '../../../article/hooks';
import { formatPrice, ICategory, TableComponent } from '../../../shared';

interface Props {
    selectedArticles: string[];
    setSelectedArticles: (selectedArticles: string[]) => void;
}

export const SelectPurchaseRegisterArticles: FC<Props> = ({ selectedArticles, setSelectedArticles }) => {
    const { t } = useTranslation();
    const { data: articles, isPending } = useArticlesAvailableForPurchaseRegister();

    useEffect(() => {
        setSelectedArticles(articles?.map(({ id }) => id) || []);
    }, [setSelectedArticles, articles]);

    const columns: GridColDef[] = [
        {
            field: 'articleNumber',
            headerName: t('articleNumber'),
            width: 200,
            sortable: false,
            valueFormatter: (_, row) => `${row.consignment?.consignmentNumber}/${row.articleNumber}`,
        },
        {
            field: 'categories',
            headerName: t('category'),
            width: 200,
            sortable: false,
            valueFormatter: (value: ICategory[]) => value?.[0]?.descriptionNl || '-',
        },
        {
            field: 'purchasePrice',
            headerName: t('purchasePrice'),
            sortable: false,
            valueFormatter: (value: number) => formatPrice(value),
            width: 200,
        },
    ];
    return (
        <TableComponent
            checkboxSelection
            rowSelectionModel={selectedArticles}
            onRowSelectionModelChange={(ids: GridRowSelectionModel) => setSelectedArticles(ids as string[])}
            rows={articles || []}
            loading={isPending}
            columns={columns}
            mode="client"
            pageSizeOptions={[100]}
            hideFooter={(articles?.length || 0) <= 100}
        />
    );
};
