import React, { FC, useState } from 'react';
import { formatPrice, FormGrid, IAuction, LabelValue, Widget } from '../../../shared';
import { Check, Clear, Edit, Folder } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { SaveAuctionDialog } from '../save-auction-dialog/save-auction-dialog.component';
import { useTranslation } from 'react-i18next';

interface Props {
    auction: IAuction;
}

export const AuctionData: FC<Props> = ({ auction }) => {
    const { t } = useTranslation();
    const [showAuctionModal, setShowAuctionModal] = useState(false);

    const pickupInfoValue = (value?: string) => {
        return value !== '' ? (
            <Check color="success" sx={{ fontSize: '18px' }} />
        ) : (
            <Clear color="warning" sx={{ fontSize: '18px' }} />
        );
    };

    return (
        <>
            <Widget
                title={t('auction')}
                icon={<Folder />}
                actions={
                    <IconButton onClick={() => setShowAuctionModal(true)}>
                        <Edit color="primary" />
                    </IconButton>
                }
            >
                <FormGrid size={{ xs: 12, sm: 4 }}>
                    <LabelValue value={auction.auctionNumber.toString()} label={t('auctionNumber')} />
                    <LabelValue value={t(auction.status)} label={t('status')} />
                    <LabelValue
                        label={t('pickupInfo')}
                        sx={{ '&.MuiStack-root': { mb: 0 } }}
                        value={
                            <Stack
                                direction="row"
                                spacing={2}
                                sx={{ '&.MuiBox-root.MuiSvgIcon-root': { fontSize: '10px' } }}
                            >
                                <LabelValue label="NL" value={pickupInfoValue(auction.pickupInfo.NL)} />
                                <LabelValue label="FR" value={pickupInfoValue(auction.pickupInfo.FR)} />
                                <LabelValue label="EN" value={pickupInfoValue(auction.pickupInfo.EN)} />
                            </Stack>
                        }
                    />
                    <LabelValue value={auction.location} label={t('location')} />
                    <LabelValue value={formatPrice(auction.fixedCostPerLot)} label={t('fixedCostPerLot')} />
                </FormGrid>

                <LabelValue value={auction.description} label={t('description')} sx={{ whiteSpace: 'pre-line' }} />
            </Widget>

            <SaveAuctionDialog open={showAuctionModal} close={() => setShowAuctionModal(false)} auction={auction} />
        </>
    );
};
