import Grid from '@mui/material/Grid2';
import { Children, ComponentProps, FC, ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface Props extends Omit<ComponentProps<typeof Grid>, 'sx'> {
    children?: ReactNode;
    containerProps?: ComponentProps<typeof Grid>;
    itemProps?: SxProps<Theme>;
}

export const FormGrid: FC<Props> = ({ children, containerProps, itemProps, ...props }) => {
    return (
        <Grid container spacing={2} {...containerProps}>
            {Children.map(children, (child, key) => {
                if (child) {
                    return (
                        <Grid key={key} sx={{ ...itemProps }} {...props}>
                            {child}
                        </Grid>
                    );
                }
            })}
        </Grid>
    );
};
