import { CloudUploadTwoTone } from '@mui/icons-material';
import { Alert, Box, styled, Typography } from '@mui/material';
import { transparentize } from 'polished';
import React, {
    ChangeEvent,
    DragEventHandler,
    FC,
    KeyboardEventHandler,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FileUploadStatus, Loading } from '../../../shared';
import { useUploadAsset } from '../../hooks';

interface Props {
    customerId: string;
}

const FilePickerLabel = styled('label')(({ theme: { palette, spacing } }) => ({
    borderStyle: 'dashed',
    borderColor: palette.divider,
    borderWidth: 1,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: spacing(4),
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
        borderColor: palette.primary.main,
        backgroundColor: transparentize(0.9, palette.primary.main),
    },
}));

const AccessibilitySpan = styled('span')(() => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    outlineOffset: '2px',
}));

export const UploadCustomerAsset: FC<Props> = ({ customerId }) => {
    const { t } = useTranslation();
    const [uploadError, setUploadError] = useState<FileUploadStatus>(FileUploadStatus.OK);

    const { mutateAsync: uploadAsset, isPending: uploading } = useUploadAsset();

    const uniqueFileUploadLabelId = `file-upload-label-${customerId}`;
    const uniqueInputElementId = `hidden-input-${customerId}`;

    const onUploadAsset = useCallback(
        async (file?: File) => {
            if (file) {
                const hasWrongFileType = !window.auction.environment.allowedFileTypes
                    .split(',')
                    .some((type) => file.type.endsWith(type[0] === '.' ? type.replace('.', '') : type));
                if (file.size > window.auction.environment.maxFileSize && hasWrongFileType) {
                    return setUploadError(FileUploadStatus.FILE_TOO_BIG_AND_WRONG_FILE_TYPE);
                }

                if (file.size > window.auction.environment.maxFileSize) {
                    return setUploadError(FileUploadStatus.FILE_TOO_BIG);
                }

                if (hasWrongFileType) {
                    return setUploadError(FileUploadStatus.WRONG_FILE_TYPE);
                }

                try {
                    await uploadAsset({ customerId, file });
                    setUploadError(FileUploadStatus.OK);
                } catch {
                    setUploadError(FileUploadStatus.GENERIC_ERROR);
                }
            }
        },
        [customerId, uploadAsset],
    );

    // Disable dragover event from the window to prevent loading the image in the browser when dropping
    useEffect(() => {
        const preventDefaultFunction = (event: DragEvent) => {
            event.preventDefault();
        };

        window.addEventListener('dragover', preventDefaultFunction);

        return () => window.removeEventListener('dragover', preventDefaultFunction);
    }, []);

    const handleDrop: DragEventHandler = useCallback(
        async (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                await onUploadAsset(e.dataTransfer.files[0]);
            }
        },
        [onUploadAsset],
    );

    // Function used to make the file-upload accessible via keyboard events
    const onEnter: KeyboardEventHandler = useCallback(
        (event) => {
            if (event.code === 'Enter') {
                const el = document.getElementById(uniqueFileUploadLabelId);
                el?.click();
            }
        },
        [uniqueFileUploadLabelId],
    );

    return (
        <>
            {uploading ? (
                <Box sx={{ p: 8 }}>
                    <Loading />
                </Box>
            ) : (
                <>
                    {uploadError !== FileUploadStatus.OK && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {t(`uploadFailed.${uploadError}`, {
                                max: (window.auction.environment.maxFileSize / (1024 * 1024)).toFixed(0),
                                types: window.auction.environment.allowedFileTypes,
                                interpolation: { escapeValue: false },
                            })}
                        </Alert>
                    )}

                    <FilePickerLabel htmlFor={uniqueInputElementId} onDrop={handleDrop} id={uniqueFileUploadLabelId}>
                        <AccessibilitySpan aria-controls={uniqueFileUploadLabelId} tabIndex={0} onKeyDown={onEnter} />

                        <CloudUploadTwoTone color="primary" fontSize="large" sx={{ mb: 1 }} />
                        <Typography>{t('uploadFile')}</Typography>
                    </FilePickerLabel>
                    <input
                        id={uniqueInputElementId}
                        type="file"
                        hidden
                        disabled={uploading}
                        multiple={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onUploadAsset(e.target.files?.[0])}
                        accept={window.auction.environment.allowedFileTypes}
                    />
                </>
            )}
        </>
    );
};
