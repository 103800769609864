import React, { FC } from 'react';
import { formatPercentage, formatPrice, FormGrid, IArticle, LabelValue, Widget } from '../../../shared';
import { useTranslation } from 'react-i18next';

interface Props {
    article: IArticle;
}

export const ArticleDetailInformation: FC<Props> = ({ article }) => {
    const { t } = useTranslation();

    return (
        <Widget title={t('general')} small>
            <FormGrid containerProps={{ spacing: 0 }} size={{ xs: 6 }}>
                <LabelValue
                    label={t('articleNumber')}
                    value={`${article.consignment.consignmentNumber}/${article?.articleNumber}`}
                />
                <LabelValue label={t('consignmentType')} value={article.consignmentType} />
                <LabelValue
                    label={t('supplier')}
                    value={
                        <a href={`/article/suppliers/${article.consignment.supplier.id}/detail`}>
                            {article.consignment.supplier.name}
                        </a>
                    }
                />
                <LabelValue label={t('commission')} value={formatPercentage(article.commission)} />
                <LabelValue label={t('minSalePrice')} value={formatPrice(article.minSalePrice)} />
            </FormGrid>
        </Widget>
    );
};
