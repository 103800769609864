import { IconButton, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { FC, Fragment, KeyboardEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ILot, Widget } from '../../../shared';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { AddCircle } from '@mui/icons-material';
import { LotInputsArticle } from './lot-inputs-article.component';
import { LotInputsGeneral } from './lot-inputs-general.component';
import { getLotArticleDefaults, setPercentages } from '../../mappers/lot-article.mapper';

interface Props {
    auctionId: string;
    lot: ILot | undefined;
}

export const LotInputs: FC<Props> = ({ auctionId, lot }) => {
    const { t } = useTranslation();
    const { control, formState, setValue, getValues } = useFormContext();
    const { fields: articleFields, append, remove } = useFieldArray({ control, name: 'articles' });

    const error: string = useMemo(
        () =>
            formState.errors?.articles?.message?.toString() ||
            formState.errors?.articles?.root?.message?.toString() ||
            '',
        [formState.errors],
    );

    const handleOnEnter = useCallback(
        (event: KeyboardEvent<HTMLButtonElement>) => {
            if (event.key === 'Enter') {
                const index = (getValues('articles')?.length - 1).toString();
                window.document.getElementById(index)?.focus();
            }
        },
        [getValues],
    );

    const handleOnTab = useCallback((event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            window.document.getElementById('save-next')?.focus();
        }
    }, []);

    return (
        <Grid container spacing={2}>
            <LotInputsGeneral auctionId={auctionId} lotId={lot?.id} />

            <Grid size={{ xs: 12, md: 9 }}>
                <Widget
                    title={t('articles')}
                    actions={
                        <IconButton
                            onClick={() => {
                                append(getLotArticleDefaults());
                                setPercentages(setValue, getValues);
                            }}
                            id="add-article"
                            onKeyUp={(event) => handleOnEnter(event)}
                            onKeyDown={(event) => handleOnTab(event)}
                        >
                            <AddCircle color="primary" />
                        </IconButton>
                    }
                >
                    {!!error && (
                        <Typography variant="caption" color="error" pl={1}>
                            {error}
                        </Typography>
                    )}

                    <Stack spacing={2} sx={{ width: '100%', overflow: 'scroll' }}>
                        {articleFields.map((field, index) => (
                            <Fragment key={field.id}>
                                <LotInputsArticle index={index} remove={remove} lot={lot} auctionId={auctionId} />
                            </Fragment>
                        ))}
                    </Stack>
                </Widget>
            </Grid>
        </Grid>
    );
};
