import React, { FC } from 'react';
import { formatPrice, FormGrid, IArticle, LabelValue, Widget } from '../../../shared';
import { useTranslation } from 'react-i18next';

interface Props {
    article: IArticle;
}

export const ArticleDetailEstimates: FC<Props> = ({ article }) => {
    const { t } = useTranslation();

    return (
        <Widget title={t('estimate')} small>
            <FormGrid containerProps={{ spacing: 0 }} size={{ xs: 6 }}>
                <LabelValue label={t('minEstimate')} value={formatPrice(article.minEstimate)} />
                <LabelValue label={t('maxEstimate')} value={formatPrice(article.maxEstimate)} />
                <LabelValue
                    label={t('estimateComment')}
                    value={article.estimateComment}
                    sx={{ whiteSpace: 'pre-line' }}
                />
            </FormGrid>
        </Widget>
    );
};
