import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
    IAuctioningLot,
    ICreditLot,
    IListLot,
    ILot,
    ILotForSignature,
    ILotQuery,
    ISaveLot,
    ISaveLotStatus,
    ISaveSignature,
    IUniqueLotCheck,
    QueryKeys,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
} from '../../shared';
import { lotClient } from '../clients';

const client = lotClient;
const listKey = QueryKeys.Lots;
const itemKey = QueryKeys.Lot;

export function useLotsList(...args: UseListParams<IListLot, ILotQuery>) {
    return useList(client, listKey, ...args);
}

export function useLotsListForSignature(auctionId: string) {
    return useQuery<ILotForSignature[], AxiosError>({
        queryKey: [QueryKeys.Lots, 'signatures'],
        queryFn: () => client.getLotsForSignature(auctionId),
        refetchInterval: 5000,
    });
}

export function useLot(...args: UseItemParams<ILot>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveLot(): UseMutationResult<ILot, AxiosError, { item: ISaveLot; id?: string }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ item, id }) => (id ? client.updateOne(id, item) : client.createOne(item)),
        onSuccess: async ({ id }) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Lots] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Lot, id] });

            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AuctionMissingLots] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AuctionSlots] });
        },
    });
}

export function useAuctioningLot(): UseMutationResult<void, AxiosError, { item: IAuctioningLot; id: string }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ item, id }) => client.auctioningLot(id, item),
        onSuccess: async (_, { id }) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Lots] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Lot, id] });

            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AuctionSlots] });
        },
    });
}

export function useDeleteLot(): UseMutationResult<ILot, AxiosError, string> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id) => client.deleteOne(id),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [listKey] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AuctionMissingLots] });
        },
    });
}

export const useSetLotStatus = (): UseMutationResult<void, AxiosError, ISaveLotStatus> => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (item) => client.setSetLotStatus(item),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Lots] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Articles] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Article] });
        },
    });
};

export const useCreditLots = (): UseMutationResult<void, AxiosError, ICreditLot> => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (item) => client.creditLots(item),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Lots] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Articles] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Article] });
        },
    });
};

export const useSaveSignature = (): UseMutationResult<void, AxiosError, ISaveSignature> => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (item) => client.saveSignature(item),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Lots] });
        },
    });
};

export const useUniqueCheckLotForAuction = (): UseMutationResult<void, AxiosError, IUniqueLotCheck> => {
    return useMutation({
        mutationFn: (item) => client.uniqueCheckLotForAuction(item),
    });
};
