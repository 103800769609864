import { InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    ConsignmentType,
    ControlledCheckbox,
    ControlledDatePicker,
    ControlledInput,
    ControlledNumberInput,
    ControlledSelect,
    formatDateString,
    FormGrid,
    IArticle,
    IParams,
    LabelValue,
    Section,
    useDebouncedState,
} from '../../../shared';
import { useConsignmentByNumber } from '../../hooks';
import { consignmentToFormMapper } from '../../mappers';
import { SelectAutocompleteSupplier } from '../save-consignment-dialog/select-autocomplete-supplier.component';

interface Props {
    article?: IArticle;
}

export const ArticleGeneralInputs: FC<Props> = ({ article }) => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const { watch, setValue } = useFormContext();
    const [debouncedConsignmentNumber, , setConsignmentNumber] = useDebouncedState('');

    const supplierName = watch('consignment.supplier.name');
    const consignment = watch('consignment');
    const consignmentType = watch('consignmentType');
    const articleNumber = watch('articleNumber');

    useEffect(() => {
        if (consignment?.consignmentNumberPart1 && consignment?.consignmentNumberPart2) {
            setConsignmentNumber(`${consignment?.consignmentNumberPart1}/${consignment?.consignmentNumberPart2}`);
        }
    }, [consignment?.consignmentNumberPart1, consignment?.consignmentNumberPart2, setConsignmentNumber]);

    const { data } = useConsignmentByNumber(debouncedConsignmentNumber);
    useEffect(() => {
        if (!id) {
            if (data) {
                setValue('consignment', consignmentToFormMapper(data));
                setValue('consignment.supplier', data.supplier);
            } else {
                setValue('consignment.id', '');
                setValue('consignment.supplier', null);
                setValue('consignment.date', '');
            }
        }
    }, [id, setValue, data]);

    useEffect(() => {
        if (article?.consignmentType != consignmentType && consignmentType === ConsignmentType.CGA) {
            setValue('paidToSupplier', true);
        }
    }, [consignmentType, setValue, article]);

    return (
        <Section title={t('general')}>
            {id ? (
                <FormGrid size={{ xs: 6, sm: 3 }} containerProps={{ width: '100%' }}>
                    <LabelValue label={t('consignment')} value={`${consignment?.consignmentNumber}/${articleNumber}`} />
                    <LabelValue label={t('date')} value={formatDateString(consignment?.date)} />
                    <LabelValue label={t('supplier')} value={supplierName} />
                </FormGrid>
            ) : (
                <Stack spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <ControlledInput
                            name="consignment.consignmentNumberPart1"
                            label={t('consignmentNumberPart1')}
                            sx={{ minWidth: 100 }}
                            required
                        />
                        <Typography>/</Typography>
                        <ControlledInput
                            name="consignment.consignmentNumberPart2"
                            label={t('consignmentNumberPart2')}
                            sx={{ minWidth: 100 }}
                            required
                        />
                        <Typography>/</Typography>
                        <ControlledNumberInput
                            name="articleNumber"
                            label={t('articleNumber')}
                            decimalScale={0}
                            sx={{ minWidth: 100 }}
                            required
                        />
                    </Stack>

                    <ControlledDatePicker
                        name="consignment.date"
                        label={t('date')}
                        disabled={!!consignment?.id}
                        required
                    />
                    <SelectAutocompleteSupplier name="consignment.supplier" disabled={!!consignment?.id} />
                </Stack>
            )}
            <FormGrid size={{ xs: 12, sm: 6, lg: 3 }} containerProps={{ alignItems: 'center' }}>
                {id && (
                    <ControlledNumberInput
                        name="articleNumber"
                        label={t('articleNumber')}
                        decimalScale={0}
                        sx={{ minWidth: 100 }}
                        required
                    />
                )}

                <ControlledSelect name="consignmentType" label={t('consignmentType')} minWidth="fit-content" required>
                    {Object.values(ConsignmentType).map((type) => (
                        <MenuItem value={type} key={type}>
                            {type}
                        </MenuItem>
                    ))}
                </ControlledSelect>

                <ControlledNumberInput
                    name="commission"
                    label={t('commission')}
                    required
                    slotProps={{ input: { endAdornment: <InputAdornment position="end" children="%" /> } }}
                    sx={{ minWidth: 100 }}
                />

                <ControlledNumberInput
                    name="minSalePrice"
                    label={t('minSalePrice')}
                    slotProps={{ input: { startAdornment: <InputAdornment position="start" children="€" /> } }}
                    sx={{ input: { pl: 1 }, minWidth: 100 }}
                />
            </FormGrid>

            <ControlledCheckbox
                name="paidToSupplier"
                sx={{ pl: 0 }}
                label={<Typography sx={{ pt: 0.5 }}>{t('paidToSupplier')} </Typography>}
            />
        </Section>
    );
};
