import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
    ICustomer,
    ICustomerQuery,
    QueryKeys,
    useDelete,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
    useSave,
} from '../../shared';
import { customerClient } from '../clients';

const client = customerClient;
const listKey = QueryKeys.Customers;
const itemKey = QueryKeys.Customer;

export function useCustomersList(...args: UseListParams<ICustomer, ICustomerQuery>) {
    return useList(client, listKey, ...args);
}

export function useCustomer(...args: UseItemParams<ICustomer>) {
    return useItem(client, itemKey, ...args);
}

export function useAuctionCustomer(auctionId: string, buyerNumber?: number) {
    return useQuery<ICustomer | null, AxiosError>({
        queryKey: [QueryKeys.Customer, { auctionId, buyerNumber }],
        queryFn: () => client.getAuctionCustomer(auctionId, buyerNumber),
        enabled: !!buyerNumber,
    });
}

export function useSaveCustomer() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteCustomer() {
    return useDelete(client, listKey);
}

export const useUploadAsset = (): UseMutationResult<void, AxiosError, { customerId: string; file: File }> => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ customerId, file }) => client.uploadAsset(customerId, file),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Customer] });
        },
    });
};

export const useDeleteAsset = (): UseMutationResult<void, AxiosError, { customerId: string; assetId: string }> => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ customerId, assetId }) => client.deleteAsset(customerId, assetId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Customer] });
        },
    });
};
