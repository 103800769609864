import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { date, object } from 'yup';
import {
    ControlledDatePicker,
    DialogTitleWithClose,
    Documentation,
    IArticleReturnForm,
    mapDateToApiDate,
    SaleStatus,
    TableComponent,
} from '../../../shared';
import { useArticlesList, useReturnArticles } from '../../hooks';
import { checkReturnArticleAllowed } from '../../utils/check-return-article-allowed.util';

interface Props {
    supplierId: string;
}

export const ReturnArticles: FC<Props> = ({ supplierId }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [selectedArticles, setSelectedArticles] = useState<string[]>([]);
    const [offeredInAuction, setOfferedInAuction] = useState<string>('');

    const { data: articles, isPending } = useArticlesList({
        supplier: supplierId,
        saleStatus: [SaleStatus.NOT_SOLD],
        isOfferedInAuction: offeredInAuction ? offeredInAuction === 'true' : undefined,
        pageSize: 1000,
    });
    const filteredArticles = articles?.data.filter(checkReturnArticleAllowed) || [];

    const { mutateAsync: saveArticleReturns, isPending: isPendingSave } = useReturnArticles();

    const columns: GridColDef[] = [
        {
            field: 'articleNumber',
            headerName: t('articleNumber'),
            minWidth: 150,
            flex: 0.25,
            valueFormatter: (value, row) => `${row.consignment?.consignmentNumber}/${row.articleNumber}`,
        },
        { field: 'descriptionNl', headerName: t('descriptionNl'), sortable: false, minWidth: 200, flex: 1 },
        {
            field: 'documentation',
            headerName: t('documentation'),
            sortable: false,
            minWidth: 200,
            flex: 0.5,
            valueFormatter: (value: Documentation[]) =>
                value.map((item) => t(`documentation_options.${item}`)).join(', '),
        },
    ];

    const form = useForm<IArticleReturnForm>({
        resolver: yupResolver(object().shape({ returnDate: date().required().typeError(t('required')) })),
        mode: 'all',
    });
    useEffect(() => {
        form.reset({ returnDate: new Date() });
    }, [form]);

    useEffect(() => {
        if (articles) {
            setSelectedArticles(articles?.data?.map(({ id }) => id));
        }
    }, [articles]);

    const onSubmit = async (item: IArticleReturnForm) => {
        await saveArticleReturns({ returnDate: mapDateToApiDate(item.returnDate) || '', articles: selectedArticles });
        setOpen(false);
    };

    const onDownloadPdf = form.handleSubmit(({ returnDate }) => {
        window.open(
            `/dvc-auction-api/articles/returns/pdf?supplier=${supplierId}&returnDate=${mapDateToApiDate(returnDate)}&articles[]=${selectedArticles.join('&articles=')}`,
            'target__blank',
        );
    });

    return (
        <>
            <Button onClick={() => setOpen(true)} variant="contained" sx={{ minWidth: '95px' }}>
                {t('return')}
            </Button>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
                    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
                        <DialogTitleWithClose onClose={() => setOpen(false)}>
                            {t('registerReturn')}
                            <Stack spacing={2} sx={{ mt: 2 }}>
                                <ControlledDatePicker name="returnDate" label={t('returnDate')} />
                                <FormControl>
                                    <InputLabel>{t('offeredInAuction')}</InputLabel>
                                    <Select
                                        variant="outlined"
                                        onChange={(e) => setOfferedInAuction(e.target.value)}
                                        value={offeredInAuction}
                                        input={<OutlinedInput notched label={t('offeredInAuction')} />}
                                    >
                                        <MenuItem value="" sx={{ p: 2 }} />
                                        <MenuItem value={'true'}>{t('yes')}</MenuItem>
                                        <MenuItem value={'false'}>{t('no')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                        </DialogTitleWithClose>
                        <DialogContent sx={{ minHeight: 200 }}>
                            <TableComponent
                                checkboxSelection
                                rowSelectionModel={selectedArticles}
                                onRowSelectionModelChange={(ids) => setSelectedArticles(ids as string[])}
                                rows={filteredArticles}
                                columns={columns}
                                mode="client"
                                pageSizeOptions={[100]}
                                hideFooter={filteredArticles.length <= 100}
                                pageSize={100}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={isPending || !selectedArticles.length}
                                variant="contained"
                                onClick={onDownloadPdf}
                            >
                                {t('download')}
                            </Button>
                            <Button
                                onClick={form.handleSubmit(onSubmit)}
                                disabled={isPending || isPendingSave || !selectedArticles.length}
                                variant="outlined"
                            >
                                {t('registerReturn')}
                            </Button>
                            <Button onClick={() => setOpen(false)}>{t('cancel')}</Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </FormProvider>
        </>
    );
};
