import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';

interface Props {
    index: number;
    open: boolean;
    close: (cancel?: boolean) => void;
}

export const ArticleInUseDialog: FC<Props> = ({ open, close }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={() => close()}>
            <DialogTitle>
                <Grid container direction="row" alignItems="center">
                    <WarningIcon color="secondary" style={{ marginRight: '0.5em' }} />
                </Grid>
            </DialogTitle>

            <DialogContent>
                <DialogContentText>{t('articleUsedWithinLotInOtherAuction')}</DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => close()}>{t('confirm')}</Button>
                <Button autoFocus variant="contained" onClick={() => close(true)}>
                    {t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
