import {
    auctionApi,
    BaseClient,
    IArticle,
    IArticleAvailableForPurchaseRegister,
    IArticleReturn,
    IArticleReturns,
    ISaveArticle,
} from '../../shared';

class ArticleClient extends BaseClient<IArticle, ISaveArticle> {
    constructor() {
        super(auctionApi, '/articles');
    }

    public async returnArticle(id: string, item: IArticleReturn): Promise<IArticle> {
        const { data } = await this.api.put<IArticle>(`/articles/${id}/return`, item);
        return data;
    }

    public async returnArticles(item: IArticleReturns): Promise<void> {
        await this.api.put<void>('/articles/returns', item);
    }

    public async getArticlesAvailableForPurchaseRegister(): Promise<IArticleAvailableForPurchaseRegister[]> {
        const { data } = await this.api.get<IArticleAvailableForPurchaseRegister[]>(
            `${this.basePath}/purchase-register`,
        );
        return data;
    }
}

export const articleClient = new ArticleClient();
