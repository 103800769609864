import { IArticle, ILotArticleForm } from '../../shared';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form/dist/types/form';

export function getLotArticleDefaults(): ILotArticleForm {
    return {
        id: '',
        artist: null,
        categories: [],
        dimensions: {
            width: '',
            height: '',
            depth: '',
            weight: '',
            length: '',
            diameter: '',
            weightUnit: 'gr',
            measurementUnit: 'mm',
        },
        shortDescription: '',
        descriptionNl: '',
        descriptionEn: '',
        percentage: '',
    };
}

export function setLotArticleDefaults(index: number, setValue: UseFormSetValue<any>) {
    setValue(`articles.${index}`, getLotArticleDefaults());
    setValue(`articles.${index}.categories`, []);
    setValue(`articles.${index}.artist`, null);
}

export function selectedArticlesInfoToLotFormMapper(getValues: UseFormGetValues<any>, setValue: UseFormSetValue<any>) {
    const articles: IArticle[] = getValues('articles');

    const minEstimate = articles?.reduce((total, article) => total + (article?.minEstimate || 0), 0);
    const maxEstimate = articles?.reduce((total, article) => total + (article?.maxEstimate || 0), 0);
    const descriptionNl = articles?.reduce(
        (total, article) =>
            `${total}${article?.descriptionNl ? (total ? '\n' + article.descriptionNl : article.descriptionNl) : ''}`,
        '',
    );
    const descriptionEn = articles?.reduce(
        (total, article) =>
            `${total}${article?.descriptionEn ? (total ? '\n' + article.descriptionEn : article.descriptionEn) : ''}`,
        '',
    );

    if (minEstimate > 0) setValue(`minEstimate`, minEstimate);
    if (maxEstimate > 0) setValue(`maxEstimate`, maxEstimate);

    if (descriptionNl !== '') setValue(`descriptionNl`, descriptionNl);
    if (descriptionEn !== '') setValue(`descriptionEn`, descriptionEn);
}

export function selectedArticleToLotFormMapper(
    article: IArticle,
    index: number,
    setValue: UseFormSetValue<any>,
    getValues: UseFormGetValues<any>,
) {
    if (article) {
        setValue(`articles.${index}`, article);
        setValue(
            `articles.${index}.categories`,
            article.categories?.map((cat) => ({ ...cat, label: cat.descriptionNl })) || [],
        );
        setValue(`articles.${index}.artist`, article.artist);
        setPercentages(setValue, getValues);
    }
}

export function setPercentages(setValue: UseFormSetValue<any>, getValues: UseFormGetValues<any>) {
    const articles = getValues('articles') || [];
    for (let index = 0; index < articles.length; index++) {
        setValue(`articles.${index}.percentage`, parseFloat((100 / articles.length).toFixed(2)));
    }
}
