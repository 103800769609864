import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormCard, IArticleForm, InUseWarning, IParams, Page, RemoveModal } from '../../../shared';
import { ArticleInputs } from '../../components';
import { useArticle, useDeleteArticle, useGetNextArticleNumber, useSaveArticle } from '../../hooks';
import { articleFromFormMapper, articleToFormMapper, setFormDefaults } from '../../mappers';
import { useArticleSchema } from '../../validators';
import { AxiosError } from 'axios';

export const ArticleEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const state = useLocation()?.state;
    const [showUniqueWarning, setShowUniqueWarning] = useState(false);

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: article, isFetching } = useArticle(id);
    const { mutateAsync: saveArticle, isPending: isPendingSave } = useSaveArticle();
    const { mutateAsync: deleteArticle } = useDeleteArticle();

    const form = useForm<IArticleForm>({
        resolver: yupResolver(useArticleSchema()),
        mode: 'all',
    });

    const consignment = form.watch('consignment');
    const consignmentSupplier = form.watch('consignment.supplier');
    const { data: nextArticleNumber } = useGetNextArticleNumber(consignment?.id || '');

    useEffect(() => {
        if (article) {
            //when updating
            form.reset(articleToFormMapper(article));
        } else if (state?.article) {
            //when copying
            form.reset({
                ...articleToFormMapper({ ...state.article, descriptionNl: '', descriptionEn: '' }),
                articleNumber: form.getValues('articleNumber'),
            });
        } else {
            // when new or next
            form.reset({ ...setFormDefaults(state?.consignment), articleNumber: form.getValues('articleNumber') });
        }
    }, [form, article, state]);

    useEffect(() => {
        if (!id && nextArticleNumber) {
            form.setValue('articleNumber', nextArticleNumber);
        }
    }, [id, form, nextArticleNumber]);

    useEffect(() => {
        if (consignmentSupplier?.id) {
            const stateSupplier = state?.consignment?.supplierId?.id || '';
            const articleSupplier = article?.consignment?.supplier?.id || '';
            if (
                (stateSupplier !== '' && consignmentSupplier?.id !== stateSupplier) ||
                (articleSupplier !== '' && consignmentSupplier?.id !== articleSupplier) ||
                (stateSupplier === '' && articleSupplier === '')
            )
                form.setValue('commission', consignmentSupplier?.standardCommission?.toString() || '');
        }
    }, [form, consignmentSupplier, article, state]);

    const onSubmit = async (item: IArticleForm, action: string) => {
        try {
            if (action === 'save') {
                const savedArticle = await saveArticle({ id: article?.id, item: articleFromFormMapper(item) });
                navigate(state?.redirect || `/article/articles/${savedArticle?.id}/detail`);
            } else if (action === 'next') {
                await saveArticle({ id: article?.id, item: articleFromFormMapper(item) });
                form.reset({
                    ...setFormDefaults(),
                    consignment: item.consignment,
                    commission: item.consignment.supplier?.standardCommission?.toString() || '',
                });
                navigate(`/article/articles/new`, {
                    state: {
                        ...state,
                        article: null,
                        consignment: item.consignment,
                    },
                });
            } else if (action === 'copy') {
                const savedArticle = await saveArticle({ id: article?.id, item: articleFromFormMapper(item) });
                form.reset({
                    ...articleToFormMapper({
                        ...savedArticle,
                        descriptionNl: '',
                        descriptionEn: '',
                    }),
                    consignment: item.consignment,
                });
                navigate(`/article/articles/new`, { state: { ...state, article: savedArticle } });
            }
        } catch (err: unknown) {
            if ((err as AxiosError)?.response?.status === 400) {
                return setShowUniqueWarning(true);
            }
            throw err;
        }
    };

    const onDelete = useCallback(async () => {
        if (id) {
            await deleteArticle(id);
            navigate(state?.redirect || `/article/articles`);
        }
    }, [deleteArticle, id, navigate, state]);

    const actions = useMemo(
        () => [
            <Button
                variant="contained"
                color="primary"
                onClick={form.handleSubmit((data) => onSubmit(data, 'next'))}
                disabled={isPendingSave}
            >
                {t('saveAndNextArticle')}
            </Button>,
            <Button
                variant="contained"
                color="primary"
                onClick={form.handleSubmit((data) => onSubmit(data, 'copy'))}
                disabled={isPendingSave}
            >
                {t('saveAndCopyArticle')}
            </Button>,
            <Button
                variant="contained"
                color="primary"
                onClick={form.handleSubmit((data) => onSubmit(data, 'save'))}
                disabled={isPendingSave}
            >
                {t('save')}
            </Button>,
            id && (
                <RemoveModal
                    handleDelete={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}
                    button={
                        <Button variant="outlined" color="primary" onClick={onDelete}>
                            {t('delete')}
                        </Button>
                    }
                    title={t('articleDeleteWarningTitle')}
                    text={t('articleDeleteWarningText')}
                />
            ),
            <Button
                onClick={() =>
                    navigate(state?.redirect || (id ? `/article/articles/${id}/detail` : `/article/articles`))
                }
                color="secondary"
            >
                {t('cancel')}
            </Button>,
        ],
        [form, isPendingSave, t, id, onDelete, onSubmit, navigate, state?.redirect],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={article ? `${article.consignment.consignmentNumber}/${article?.articleNumber}` : t('newArticle')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isPendingSave}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit((data) => onSubmit(data, 'save'))} actions={actions}>
                    <ArticleInputs article={article} />
                    <InUseWarning
                        show={showUniqueWarning}
                        onClose={() => setShowUniqueWarning(false)}
                        text={t('uniqueArticleNumberWarning')}
                    />
                </FormCard>
            </FormProvider>
        </Page>
    );
};
