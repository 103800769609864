import { Close, Done } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ILotForSignature, IParams, Page, SideBar, SideNav } from '../../../shared';
import { Signature } from '../../components';
import { useLotsListForSignature } from '../../hooks';

export const AuctionSignaturesPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const current = params.get('current');
    const [currentLot, setCurrentLot] = useState<ILotForSignature>();

    const { data: lots } = useLotsListForSignature(id);

    useEffect(() => {
        if (current) {
            setCurrentLot(lots?.find((lot) => lot.id === current));
        } else {
            setCurrentLot(lots?.find((lot) => !lot.signature));
        }
    }, [current, currentLot, lots]);

    const currentLotIndex = useMemo(() => lots?.findIndex((l) => l.id === currentLot?.id), [lots, currentLot]);

    const nextLot = () => {
        if (lots && currentLotIndex !== undefined) {
            const lot = lots[currentLotIndex + 1];
            navigate(`?current=${lot?.id || ''}`);
        }
    };

    const previousLot = () => {
        if (lots && currentLotIndex) {
            const lot = currentLotIndex >= 0 ? lots[currentLotIndex - 1] : lots[lots.length - 1];
            navigate(`?current=${lot?.id || ''}`);
        }
    };

    return (
        <Page
            title={t('signatures')}
            actions={[
                <Button variant="outlined" onClick={previousLot} disabled={currentLotIndex === 0}>
                    {t('previous')}
                </Button>,
                <Button variant="contained" onClick={nextLot} disabled={!currentLot || !currentLot?.signature}>
                    {t('next')}
                </Button>,
            ]}
        >
            <SideBar>
                <SideNav
                    items={
                        lots?.map((lot) => ({
                            path: `?current=${lot.id}`,
                            text: `${lot.lotNumber}${lot.lotNumberSuffix || ''}`,
                            icon: lot.signature ? <Done /> : <Close color="error" />,
                            selected: lot.id === currentLot?.id,
                        })) || []
                    }
                />
            </SideBar>
            {currentLot ? (
                <Signature currentLot={currentLot} />
            ) : (
                <Box sx={{ textAlign: 'center' }}>
                    <Typography>{t('noLotsWaitingForSignature')}</Typography>
                </Box>
            )}
        </Page>
    );
};
