import { Button, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { addressFormatter, ILotForSignature, LabelValue, Widget } from '../../../shared';
import { useCustomer } from '../../hooks';

interface Props {
    currentLot: ILotForSignature;
}

export const CustomerInfo: FC<Props> = ({ currentLot }) => {
    const { t } = useTranslation();
    const { data: customer } = useCustomer(currentLot.customerId);

    return (
        <Widget
            title={t('customer')}
            actions={
                <Button
                    variant="outlined"
                    onClick={() => window.open(`/auction/customers/${currentLot.customerId}/edit`)}
                >
                    {t('editCustomer')}
                </Button>
            }
        >
            <LabelValue label={t('buyerNumber')} value={currentLot.buyerNumber?.toString()} />
            <LabelValue label={t('customer')} value={customer?.name} />
            <LabelValue
                label={t('contact')}
                value={
                    customer && (
                        <Stack direction="column">
                            {customer.contactDetails?.some((contact) => contact.data)
                                ? customer.contactDetails?.map((contact) => (
                                      <Typography key={contact.id}>{contact.data}</Typography>
                                  ))
                                : '-'}
                        </Stack>
                    )
                }
            />
            <LabelValue label={t('address')} value={customer && addressFormatter(customer.address)} />
        </Widget>
    );
};
