import React, { FC, useMemo } from 'react';
import { Button, IconButton, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ILot } from '../../../shared';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Params {
    currentLot: ILot;
    auctionId: string;
    onNext: () => void;
    lots?: ILot[];
}

export const ActiveSlotNavigation: FC<Params> = ({ currentLot, auctionId, onNext, lots }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const currentLotIndex = useMemo(() => lots?.findIndex((l) => l.id === currentLot?.id), [lots, currentLot]);

    const onGoBack = () => {
        if (lots && currentLotIndex !== undefined) {
            const lot = lots[currentLotIndex - 1];
            navigate(`?current=${lot?.lotNumber}${lot?.lotNumberSuffix || ''}`);
        }
    };

    return (
        <>
            {lots && (
                <Grid container alignItems="center" spacing={2} mb={4} pt={1}>
                    <Grid size={{ xs: 3.5 }}>
                        <Button variant="outlined" onClick={() => navigate(`/auction/auctions/${auctionId}/detail`)}>
                            {t('goBackToOverview')}
                        </Button>
                    </Grid>

                    <Grid size={{ xs: 5 }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <IconButton onClick={onGoBack} disabled={currentLotIndex === 0}>
                                <KeyboardArrowLeft />
                            </IconButton>
                            {(currentLotIndex || 0) + 1} / {lots.length}
                            <IconButton onClick={onNext} disabled={currentLotIndex === lots.length - 1}>
                                <KeyboardArrowRight />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            )}
        </>
    );
};
