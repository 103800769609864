import { IAuctioningLot, IAuctioningLotForm, ILot } from '../../shared';

export const auctioningLotToFormMapper = (item: ILot): IAuctioningLotForm => {
    return {
        ...item,
        buyerNumber: item.buyerNumber || '',
        hammerPrice: item.hammerPrice || '',
    };
};

export const auctioningLotFromFormMapper = (item: IAuctioningLotForm): IAuctioningLot => {
    return {
        ...item,
        buyerNumber: item.buyerNumber ? Number(item.buyerNumber) : null,
        hammerPrice: item.hammerPrice ? Number(item.hammerPrice) : null,
    };
};
