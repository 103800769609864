import { Autocomplete, Tab, Tabs, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addressFormatter, ICustomer, useDebouncedState } from '../../../shared';
import { useCustomersList } from '../../hooks';

interface Props {
    disabled?: boolean;
    value: ICustomer | null | undefined;
    setValue: (value: ICustomer | null) => void;
}

enum searchType {
    NAME = 'NAME',
    ADDRESS = 'ADDRESS',
}
export const SelectCustomer: FC<Props> = ({ disabled, value, setValue }) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState<searchType>(searchType.NAME);
    const [debouncedSearch, , setSearch] = useDebouncedState('');
    const { data: customers, isPending } = useCustomersList({
        search: selectedTab === searchType.NAME ? debouncedSearch : undefined,
        address: selectedTab === searchType.ADDRESS ? debouncedSearch : undefined,
    });

    return (
        <>
            <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={selectedTab}
                onChange={(e, value) => setSelectedTab(value)}
                sx={{ mb: 2 }}
            >
                <Tab label={t('search')} value={searchType.NAME} />
                <Tab label={t('searchAddress')} value={searchType.ADDRESS} />
            </Tabs>
            <Autocomplete
                onChange={(_, data) => setValue(data)}
                value={value}
                options={debouncedSearch ? customers?.data || [] : []}
                getOptionLabel={(customer) =>
                    `${customer.customerNumber}: ${customer.name} - ${addressFormatter(customer.address)}`
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onInputChange={(e, value) => setSearch(value)}
                noOptionsText={debouncedSearch ? t('noCustomerFound') : t('startSearchingCustomers')}
                loading={isPending}
                disabled={disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t('customer')}
                        slotProps={{ inputLabel: { ...params.InputLabelProps, shrink: true } }}
                    />
                )}
            />
        </>
    );
};
