import { Button, InputAdornment } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    getCountries,
    ContactDataInputs,
    ContactType,
    ControlledAutocomplete,
    ControlledInput,
    ControlledNumberInput,
    FormGrid,
    ISupplierForm,
    Section,
} from '../../../shared';

interface Props {
    id?: string;
    form: UseFormReturn<ISupplierForm>;
}

export const SupplierDataInputs: FC<Props> = ({ form }) => {
    const { t } = useTranslation();

    const {
        fields: contactDetails,
        append,
        remove,
    } = useFieldArray({
        control: form.control,
        name: 'contactDetails',
    });

    return (
        <>
            <Section title={t('data')}>
                <FormGrid size={{ xs: 12, md: 'auto' }}>
                    <ControlledInput name="name" label={t('name')} required />
                    <ControlledInput name="initials" label={t('initials')} />
                    <ControlledInput name="accountNumber" label={t('accountNumber')} />
                    <ControlledNumberInput
                        name="standardCommission"
                        label={t('standardCommission')}
                        slotProps={{ input: { endAdornment: <InputAdornment position="end" children="%" /> } }}
                    />
                </FormGrid>
            </Section>

            <Section title={t('comment')}>
                <ControlledInput name="comment" required multiline rows={4} sx={{ minWidth: 825 }} />
            </Section>

            <Section title={t('address')}>
                <FormGrid size={{ xs: 12, md: 'auto' }}>
                    <ControlledInput name="address.street" label={t('street')} sx={{ minWidth: 615 }} />
                    <ControlledInput name="address.number" label={t('number')} sx={{ minWidth: 150 }} />
                </FormGrid>
                <FormGrid size={{ xs: 12, md: 'auto' }}>
                    <ControlledInput name="address.postalCode" label={t('postalCode')} sx={{ minWidth: 150 }} />
                    <ControlledInput name="address.city" label={t('city')} />
                    <ControlledAutocomplete
                        name="address.country"
                        label={t('country')}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        options={getCountries}
                        required
                    />
                </FormGrid>
            </Section>

            <Section title={t('contactDetails')}>
                {contactDetails.map((contact, index) => (
                    <Fragment key={contact.id}>
                        <ContactDataInputs index={index} remove={remove} prefix="" />
                    </Fragment>
                ))}
                <Button
                    onClick={() => append({ type: ContactType.Email, data: '' })}
                    color="secondary"
                    sx={{ span: { mb: 0.5, mr: 1 } }}
                >
                    <span>+</span> {t('contactDetailsAdd')}
                </Button>
            </Section>
        </>
    );
};
