import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
    ControlledInput,
    FormCard,
    IConditions,
    Page,
    PermissionKeys,
    Section,
    useHasPermission,
} from '../../../shared';
import { useTranslation } from 'react-i18next';
import { useConditions, useUpdateConditions } from '../../hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useConditionsSchema } from '../../validators';
import { Button } from '@mui/material';

export const TermsAndConditionsPage: FC = () => {
    const { t } = useTranslation();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.CONDITIONS_WRITE);

    const { data: conditions, isFetching } = useConditions();
    const { mutateAsync: updateConditions, isPending: isPendingSave } = useUpdateConditions();

    const form = useForm<IConditions>({
        resolver: yupResolver(useConditionsSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (conditions) form.reset(conditions);
    }, [form, conditions]);

    const onSubmit = useCallback(
        async (item: IConditions) => {
            await updateConditions(item);
        },
        [updateConditions],
    );

    const actions = useMemo(
        () => [
            hasWritePermission && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={form.handleSubmit(onSubmit)}
                    disabled={isPendingSave}
                >
                    {t('save')}
                </Button>
            ),
        ],
        [hasWritePermission, form, onSubmit, isPendingSave, t],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page title={t('termsAndConditions')} loading={isFetching} actions={reversedActions}>
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Section>
                        <ControlledInput
                            name="consignment"
                            label={t('consignmentConditions')}
                            required
                            multiline
                            rows={8}
                        />
                        <ControlledInput name="auction" label={t('auctionConditions')} required multiline rows={8} />
                    </Section>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
