import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogContent, Stack, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, FormGrid, IMissingAndUnassignedLots, Section } from '../../../shared';
import { ExpandMore } from '@mui/icons-material';

interface Props {
    open: boolean;
    close: () => void;
    ranges?: IMissingAndUnassignedLots;
}

export const MissingAuctionLots: FC<Props> = ({ open, close, ranges }) => {
    const { t } = useTranslation();
    const { palette } = useTheme();

    return (
        <Dialog
            open={open}
            onClose={close}
            fullWidth
            maxWidth="md"
            sx={{ '.MuiDialog-paper': { backgroundColor: palette.background.default } }}
        >
            <DialogTitleWithClose onClose={close} />

            <DialogContent sx={{ mb: 2 }}>
                <FormGrid size={{ xs: 6 }}>
                    <Section title={t('missingAuctionLots')}>
                        <Stack spacing={2}>
                            {ranges?.missing.map((range) => (
                                <Accordion key={range.startOfRange}>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        {`${range.startOfRange}${range.startOfRange !== range.endOfRange ? ` - ${range.endOfRange}` : ''}`}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {range.lots
                                            ?.map((lot) => `${lot.lotNumber}${lot.lotNumberSuffix || ''}`)
                                            .join(', ')}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Stack>
                    </Section>

                    <Section title={t('unassignedAuctionLots')}>
                        <Stack spacing={2}>
                            {ranges?.unassigned.map((range) => (
                                <Accordion key={range.startOfRange}>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        {`${range.startOfRange}${range.startOfRange !== range.endOfRange ? `- ${range.endOfRange}` : ''}`}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {range.lots
                                            ?.map((lot) => `${lot.lotNumber}${lot.lotNumberSuffix || ''}`)
                                            .join(', ')}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Stack>
                    </Section>
                </FormGrid>
            </DialogContent>
        </Dialog>
    );
};
