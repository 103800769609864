import { DeleteOutline } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isBadRequest } from '../../../shared/utils/exception.util';
import { useDeleteCustomer } from '../../hooks';

interface Props {
    customerId: string;
    iconButton?: boolean;
    navigateTo?: string;
    setShowInUseError: (val: boolean) => void;
}

export const DeleteCustomer: FC<Props> = ({ customerId, iconButton, navigateTo, setShowInUseError }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showDelete, setShowDelete] = useState(false);

    const { mutateAsync: deleteCustomer } = useDeleteCustomer();

    const onDelete = async () => {
        try {
            setShowDelete(false);
            await deleteCustomer(customerId);
            if (navigateTo) navigate(navigateTo);
        } catch (e) {
            if (isBadRequest(e)) {
                return setShowInUseError(true);
            }
            throw e;
        }
    };

    return (
        <>
            {iconButton ? (
                <IconButton
                    aria-label="delete"
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowDelete(true);
                    }}
                >
                    <DeleteOutline />
                </IconButton>
            ) : (
                <Button variant="contained" onClick={() => setShowDelete(true)}>
                    {t('delete')}
                </Button>
            )}

            <Dialog open={showDelete} onClose={() => setShowDelete(false)}>
                <DialogTitle>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <WarningIcon color="secondary" />
                        <Typography variant="inherit">{t('customerDeleteWarningTitle')}</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('customerDeleteWarningText')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" spacing={2}>
                        <Button
                            onClick={() => onDelete()}
                            color="secondary"
                            autoFocus
                            variant="contained"
                            aria-label="confirm-delete"
                        >
                            {t('confirm')}
                        </Button>
                        <Button onClick={() => setShowDelete(false)} color="secondary">
                            {t('cancel')}
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};
