import { Button, Chip } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IAuctionSlot } from '../../../shared';
import { AuctionSlotStatus } from '../../../shared/enums/auction-slot-status.enum';
import { AuctionStatus } from '../../../shared/enums/auction-status.enum';
import { useCloseAuctionSlot, useUpdateAuctionStatus } from '../../hooks';

interface Props {
    auctionId: string;
    auctionSlot: IAuctionSlot;
}

export const AuctionSlotStatusAction: FC<Props> = ({ auctionSlot, auctionId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { mutateAsync: updateAuctionStatus } = useUpdateAuctionStatus();
    const { mutateAsync: closeAuctionSlot, isPending: auctionSlotIsClosing } = useCloseAuctionSlot();

    const onStartAuctionSlot = async () => {
        updateAuctionStatus({ auctionId, status: AuctionStatus.ONGOING });
        navigate(`../auctions/${auctionId}/lots/${auctionSlot.firstLotNumber}/${auctionSlot.lastLotNumber}`);
    };
    return (
        <>
            {auctionSlot.status === AuctionSlotStatus.OPEN && (
                <Button variant="outlined" size="small" sx={{ mr: 1 }} onClick={onStartAuctionSlot}>
                    {t('start')}
                </Button>
            )}

            {auctionSlot.status === AuctionSlotStatus.OPEN && auctionSlot.readyForClosing && (
                <Button
                    variant="contained"
                    size="small"
                    sx={{ mr: 1 }}
                    onClick={() => closeAuctionSlot(auctionSlot.id)}
                    disabled={auctionSlotIsClosing}
                >
                    {t('close')}
                </Button>
            )}
            {auctionSlot.status === AuctionSlotStatus.CLOSED && <Chip label={t('closed')} />}
        </>
    );
};
