import { Box, Button, Dialog } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, ILotQuery } from '../../../shared';
import { SelectBuyer } from '../select-buyer/select-buyer.component';
import { CreditSelectLots } from './credit-select-lots.component';

export const CreditLots: FC = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [buyer, setBuyer] = useState<ILotQuery>();

    const onClose = () => {
        setOpen(false);
        setBuyer(undefined);
    };

    return (
        <Box>
            <Button onClick={() => setOpen(true)} fullWidth variant="outlined" sx={{ height: '40px' }}>
                {t('creditLots')}
            </Button>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={{}}>
                <DialogTitleWithClose onClose={onClose}>{t('creditLots')}</DialogTitleWithClose>
                {buyer ? (
                    <CreditSelectLots buyer={buyer} close={onClose} />
                ) : (
                    <SelectBuyer onCancel={onClose} onNext={setBuyer} />
                )}
            </Dialog>
        </Box>
    );
};
