import { AddCircle, Article } from '@mui/icons-material';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    formatPrice,
    IArticle,
    IAuction,
    RowActions,
    SearchField,
    SortOrder,
    TableComponent,
    useQueryParams,
    Widget,
} from '../../../shared';
import { useDeleteLot, useGetMissingAuctionLots, useLotsList } from '../../hooks';
import { MissingAuctionLots } from '../missing-auction-lots/missing-auction-lots.component';

interface Props {
    auction: IAuction;
}

export const InPreparationAuctionLotTable: FC<Props> = ({ auction }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showMissingAuctionLots, setShowMissingAuctionLots] = useState(false);
    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'lotNumber', sort: 'asc' as GridSortDirection }],
        defaultPageSize: 20,
    });

    const { data: lots, isPending } = useLotsList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
        auctionId: auction.id,
    });
    const { data: ranges } = useGetMissingAuctionLots(auction.id);
    const { mutateAsync: deleteLot } = useDeleteLot();

    const columns: GridColDef[] = [
        {
            field: 'lotNumber',
            headerName: t('lotNumber'),
            sortable: false,
            flex: 0.5,
            valueFormatter: (value, row) => `${row.lotNumber}${row.lotNumberSuffix || ''}`,
        },
        {
            field: 'articles',
            headerName: t('articles'),
            sortable: false,
            flex: 1,
            renderCell: ({ value }) => (
                <Stack direction="row" spacing={1}>
                    {value.map((article: IArticle, index: number) => (
                        <Fragment key={index}>
                            <a href={`/article/articles/${article.id}/detail`}>
                                {`${article.consignment?.consignmentNumber}/${article.articleNumber}`}
                            </a>
                            {index < value.length - 1 ? ', ' : ''}
                        </Fragment>
                    ))}
                </Stack>
            ),
        },
        {
            field: 'minEstimate',
            headerName: t('minEstimate'),
            sortable: false,
            flex: 0.5,
            valueFormatter: (value: number) => formatPrice(value),
        },
        {
            field: 'maxEstimate',
            headerName: t('maxEstimate'),
            sortable: false,
            flex: 0.5,
            valueFormatter: (value: number) => formatPrice(value),
        },

        {
            field: 'descriptionNl',
            headerName: t('descriptionNl'),
            sortable: false,
            flex: 1,
        },
        {
            field: ' ',
            headerName: '',
            flex: 0.5,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <RowActions
                    onEdit={() => navigate(`/auction/auctions/lot/${row.id}/edit`, { state: auction })}
                    onDelete={() => deleteLot(row.id)}
                    deleteWarningTitle={t('lotDeleteWarningTitle')}
                    deleteWarningText={t('lotDeleteWarningText')}
                />
            ),
        },
    ];

    return (
        <Widget
            title={
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="subtitle2">{t('lots')}</Typography>
                </Stack>
            }
            icon={<Article />}
            hasTable
            actions={
                <Stack direction="row" spacing={1}>
                    {ranges && (ranges.missing.length > 0 || ranges.unassigned.length > 0) && (
                        <Button
                            variant="outlined"
                            sx={{ minWidth: 'fit-content' }}
                            onClick={() => setShowMissingAuctionLots(true)}
                        >
                            {t('showMissingAuctionLots')}
                        </Button>
                    )}
                    <SearchField search={search} onSearch={setSearch} />
                    <IconButton onClick={() => navigate('/auction/auctions/lot/new', { state: auction })}>
                        <AddCircle color="primary" />
                    </IconButton>
                </Stack>
            }
        >
            <TableComponent
                rows={lots?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={lots?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
            />
            <MissingAuctionLots
                open={showMissingAuctionLots}
                close={() => setShowMissingAuctionLots(false)}
                ranges={ranges}
            />
        </Widget>
    );
};
