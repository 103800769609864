import { Box, Drawer, styled } from '@mui/material';
import { FC, ReactNode, useEffect } from 'react';
import { useShowDrawer } from '../../contexts';

const DrawerContainer = styled(Box)(({ theme }) => ({
    padding: `${theme.spacing(7)} 0`,
}));

interface Props {
    children?: ReactNode;
}

export const SideBar: FC<Props> = ({ children }) => {
    const { showDrawer, setShowDrawer, setHasDrawer } = useShowDrawer();
    const handleDrawerToggle = () => {
        setShowDrawer(!showDrawer);
    };

    const width = 245;

    useEffect(() => {
        setHasDrawer(true);
        return () => setHasDrawer(false);
    }, [setHasDrawer]);

    return (
        <>
            <Drawer
                sx={{
                    width,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width,
                        boxSizing: 'border-box',
                    },
                    display: { xs: 'block', xl: 'none' },
                }}
                variant="temporary"
                open={showDrawer}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <DrawerContainer onClick={handleDrawerToggle}>{children}</DrawerContainer>
            </Drawer>

            <Drawer
                sx={{
                    width,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width,
                        boxSizing: 'border-box',
                    },
                    display: { xs: 'none', xl: 'block' },
                }}
                variant="persistent"
                open
            >
                <DrawerContainer>{children}</DrawerContainer>
            </Drawer>
        </>
    );
};
