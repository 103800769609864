import {
    getCountries,
    ICustomer,
    ICustomerAddress,
    ICustomerAddressForm,
    ICustomerForm,
    ILocation,
    ILocationInvite,
    IOrigins,
    ISaveCustomer,
} from '../../shared';

export const newCustomer = (locations: ILocation[]): Required<ICustomerForm> => {
    return {
        name: '',
        contactDetails: [],
        comment: '',
        accountNumber: '',
        taxNumber: '',
        companyName: '',
        invites: locations?.map((location) => ({
            location: location.name,
            wantsInvite: false,
        })),
        address: { street: '', number: '', postalCode: '', city: '', country: { code: 'be', label: 'België' } },
    };
};

export const customerToFormMapper = (item: ICustomer, locations: ILocation[]): ICustomerForm => {
    return {
        ...item,
        address: customerAddressToFormMapper(item?.address),
        invites: customerInvitesToFormMapper(locations, item?.invites),
    };
};

export const customerAddressToFormMapper = (address?: ICustomerAddress): ICustomerAddressForm => {
    return address
        ? {
              ...address,
              country: getCountries.find(
                  ({ label }) => label.toLowerCase() === address.country.toLowerCase(),
              ) as IOrigins,
          }
        : { street: '', number: '', postalCode: '', city: '', country: { code: 'be', label: 'België' } };
};

export const customerInvitesToFormMapper = (locations: ILocation[], invites?: ILocationInvite[]): ILocationInvite[] => {
    return locations?.map((location) => ({
        location: location.name,
        wantsInvite: invites?.find((invite) => invite.location === location.name)?.wantsInvite || false,
    }));
};

export const customerFromFormMapper = (item: ICustomerForm): ISaveCustomer => {
    return {
        ...item,
        address: { ...item.address, country: item.address.country.label },
    };
};
