import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { authApi } from '../clients';
import { QueryKeys } from '../enums';
import { AuthListQueryParam, IUserRoles, ListModelItems } from '../models';

async function getUserRoles(
    params: AuthListQueryParam,
    onGetErrorMessage?: string,
): Promise<ListModelItems<IUserRoles>> {
    const { data } = await authApi.get(`/v1/authz/acls`, {
        params: {
            ...params,
            limit: 1000,
        },
        errorMessage: onGetErrorMessage,
    });

    return data;
}

export const useGetUserRoles = (enabled: boolean, params: AuthListQueryParam = {}) => {
    const { t } = useTranslation();

    return useQuery<ListModelItems<IUserRoles>, AxiosError>({
        queryKey: [QueryKeys.UserRoles, params],
        queryFn: () => getUserRoles(params, t('getUserRolesError')),
        enabled,
    });
};
