import { IArtist, IArtistForm, ISaveArtist, getNationalities } from '../../shared';

export const artistToFormMapper = (item: IArtist): IArtistForm => {
    return {
        ...item,
        nationality:
            getNationalities.find(({ code }) => code.toLowerCase() === item?.nationality?.toLowerCase()) || null,
    };
};

export const artistFromFormMapper = (item: IArtistForm): ISaveArtist => {
    return {
        ...item,
        nationality: item.nationality?.code?.toLowerCase() || '',
        yearOfBirth: item.yearOfBirth ? Number(item.yearOfBirth) : null,
        yearOfDeath: item.yearOfDeath ? Number(item.yearOfDeath) : null,
    };
};
