import { Delete } from '@mui/icons-material';
import { IconButton, InputAdornment, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArticleDimensionInputs } from '../../../article/components/article-inputs/article-dimension-inputs.component';
import { SelectAutocompleteArtist } from '../../../article/components/article-inputs/select-autocomplete-artist.component';
import { SelectAutocompleteCategories } from '../../../article/components/article-inputs/select-autocomplete-categories.component';
import { ControlledNumberInput, formatPrice, FormGrid, ILot, ILotArticleForm, LabelValue } from '../../../shared';
import { setLotArticleDefaults, setPercentages } from '../../mappers/lot-article.mapper';
import { ArticleInUseDialog } from '../in-use-warnings/article-in-use-dialog.component';
import { SelectAutocompleteLotArticle } from '../select-autocomplete-lot-article/select-autocomplete-lot-article.component';
import { LotInputsArticleDescription } from './lot-inputs-article-description.component';

interface Props {
    index: number;
    remove: (index: number) => void;
    lot?: ILot;
    auctionId?: string;
}

export const LotInputsArticle: FC<Props> = ({ index, remove, lot, auctionId }) => {
    const { t } = useTranslation();
    const [inUseWarning, setInUseWarning] = useState(false);

    const { getValues, setValue, watch } = useFormContext();

    const watchArticleId = watch(`articles.${index}.id`);
    const currentArticle = useMemo(() => {
        const articles: ILotArticleForm[] = getValues('articles');
        return (
            articles?.find(({ id }) => id === watchArticleId) || lot?.articles?.find(({ id }) => id === watchArticleId)
        );
    }, [getValues, index, lot?.articles, watchArticleId]);

    const handleClose = useCallback(
        (cancel = false) => {
            setInUseWarning(false);
            if (cancel) {
                setLotArticleDefaults(index, setValue);
            }
        },
        [setValue, index],
    );

    return (
        <>
            <Grid container sx={{ pb: 4, justifyContent: 'space-between' }}>
                <Grid size={{ xs: 11 }}>
                    <FormGrid size={{ xs: 12, sm: 'auto' }} containerProps={{ justifyContent: 'space-between', py: 1 }}>
                        <SelectAutocompleteLotArticle
                            index={index}
                            lot={lot}
                            auctionId={auctionId}
                            setInUseWarning={setInUseWarning}
                        />
                        {getValues('articles')?.length > 1 && (
                            <ControlledNumberInput
                                name={`articles.${index}.percentage`}
                                label={t('percentage')}
                                slotProps={{ input: { endAdornment: <InputAdornment position="end" children="%" /> } }}
                                sx={{ minWidth: 100, maxWidth: 125 }}
                            />
                        )}
                    </FormGrid>
                    <Stack direction="row" spacing={4} mb={2}>
                        <LabelValue label={t('supplier')} value={currentArticle?.consignment?.supplier?.name} />
                        <LabelValue label={t('consignmentType')} value={currentArticle?.consignmentType} />
                        <LabelValue label={t('minSalePrice')} value={formatPrice(currentArticle?.minSalePrice)} />
                        <LabelValue label={t('minEstimate')} value={formatPrice(currentArticle?.minEstimate)} />
                        <LabelValue label={t('maxEstimate')} value={formatPrice(currentArticle?.maxEstimate)} />
                    </Stack>

                    <FormGrid size={{ xs: 12, md: 6 }} itemProps={{ width: '100%' }}>
                        <Stack spacing={2}>
                            <SelectAutocompleteArtist prefix={`articles.${index}.`} />
                            <SelectAutocompleteCategories prefix={`articles.${index}.`} />
                            <ArticleDimensionInputs prefix={`articles.${index}.`} />
                        </Stack>

                        <LotInputsArticleDescription index={index} />
                    </FormGrid>
                </Grid>

                <Grid size={{ xs: 'auto' }} sx={{ textAlign: 'right' }}>
                    <IconButton
                        onClick={() => {
                            remove(index);
                            setPercentages(setValue, getValues);
                        }}
                        sx={{ pr: 2, alignSelf: 'end' }}
                        onKeyDown={(event) => {
                            if (event.key.toLowerCase() === 'tab' && getValues('articles')?.length - 1 === index) {
                                event.preventDefault();
                                window.document.getElementById('add-article')?.focus();
                            }
                        }}
                    >
                        <Delete />
                    </IconButton>
                </Grid>
            </Grid>

            <ArticleInUseDialog index={index} open={inUseWarning} close={(cancel?: boolean) => handleClose(cancel)} />
        </>
    );
};
