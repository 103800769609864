import { Button, Dialog } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, ILotQuery } from '../../../shared';
import { SelectBuyer } from '../select-buyer/select-buyer.component';
import { PaymentSelectLots } from '../payment-select-lots/payment-select-lots.component';

export const RegisterPayment: FC = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [buyer, setBuyer] = useState<ILotQuery>();

    const onClose = () => {
        setOpen(false);
        setBuyer(undefined);
    };

    return (
        <>
            <Button onClick={() => setOpen(true)} fullWidth variant="outlined">
                {t('registerPayments')}
            </Button>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
                <DialogTitleWithClose onClose={onClose}>{t('registerPayments')}</DialogTitleWithClose>
                {buyer ? (
                    <PaymentSelectLots buyer={buyer} close={onClose} />
                ) : (
                    <SelectBuyer onCancel={onClose} onNext={setBuyer} />
                )}
            </Dialog>
        </>
    );
};
