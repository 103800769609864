import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDefaultAuctionCost } from '../../../admin/hooks';
import { DialogTitleWithClose, IAuction, ISaveAuction } from '../../../shared';
import { useSaveAuction } from '../../hooks';
import { useAuctionSchema } from '../../validators';
import { SaveAuctionDialogInputs } from './save-auction-dialog-inputs.component';

interface Props {
    open: boolean;
    close: () => void;
    auction?: IAuction;
}

export const SaveAuctionDialog: FC<Props> = ({ open, close, auction }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { mutateAsync: saveAuction, isPending: isSaving } = useSaveAuction();
    const { data: defaultAuctionCost } = useDefaultAuctionCost();

    const form = useForm<ISaveAuction>({
        resolver: yupResolver(useAuctionSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (open) {
            if (auction) {
                form.reset(auction);
            } else {
                form.reset({ ...defaultAuctionCost });
            }
        }
    }, [form, open, auction, defaultAuctionCost]);

    const onSubmit = async (item: ISaveAuction) => {
        if (auction) {
            await saveAuction({ id: auction.id, item });
            close();
        } else {
            const { id } = await saveAuction({ item });
            close();
            navigate(`/auction/auctions/${id}/detail`);
        }
    };

    return (
        <Dialog open={open} onClose={close} fullWidth maxWidth="lg">
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <DialogTitleWithClose onClose={close}>
                        {auction ? `${t('editAuction')} ${auction.auctionNumber}` : t('newAuction')}
                    </DialogTitleWithClose>

                    <SaveAuctionDialogInputs />

                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary" disabled={isSaving}>
                            {t('save')}
                        </Button>
                        <Button onClick={close} color="secondary" disabled={isSaving}>
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};
