export default {
    pages: {
        login: 'Aanmelden | DVC',
        auctions: 'Veilingen | DVC',
        articles: 'Artikelen | DVC',
        admin: 'Admin | DVC',
    },

    nav: {
        title: 'DVC',
    },

    logOut: 'Uitloggen',
    logIn: 'Inloggen',

    somethingWentWrong: 'Er liep iets mis:',
    back: 'Terug',
    save: 'Bewaar',
    cancel: 'Annuleer',
    delete: 'Verwijder',
    confirm: 'Bevestig',
    next: 'Volgende',
    saveAndNext: 'Bewaar en volgende',
    previous: 'Vorige',
    edit: 'Bewerk',
    no: 'Nee',
    yes: 'Ja',
    unknown: 'Onbekend',

    skipToMainContentText: 'Ga naar de hoofdinhoud',
    accessibility: {
        profileButton: 'Profiel knop',
        account: 'Gebruiker',
        generalInformation: ' Algemene informatie',
        privacyPolicy: 'Privacy verklaring',
        faq: 'Veelgestelde vragen',
    },

    description: 'Beschrijving',
    descriptionNl: 'Beschrijving NL',
    descriptionEn: 'Beschrijving EN',

    comments: 'Commentaar',
    data: 'Gegevens',
    goBack: 'Ga terug naar de vorige pagina',
    goBackToOverview: 'Ga terug naar overzicht',
    noInfo: 'Geen gegevens beschikbaar.',
    ok: 'ok',
    pageNotFoundErr: 'Deze pagina kon niet worden gevonden.',
    search: 'Zoek',
    searchAddress: 'Zoek op adres',
    home: 'Home',
    type: 'Type',
    email: 'Email',
    phone: 'Telefoonnummer',
    address: 'Adres',
    street: 'Straat',
    number: 'Nummer',
    postalCode: 'Postcode',
    city: 'Stad',
    country: 'Land',
    total: 'Totaal',
    all: 'Alle',
    complete: 'Voltooi',
    modify: 'Wijzig',
    filter: 'Filter',
    extraOptions: 'Extra opties',
    options: 'Opties',
    addNewValue: 'Voeg "{{value}}" toe',
    createdDate: 'Aanmaakdatum',
    history: 'Historiek',
    date: 'Datum',
    saveSuccess: 'Successvol opgeslagen',
    tryAgainLater:
        'Probeer later nog eens, indien deze foutmelding zich opnieuw voordoet: contacteer de verantwoordelijke.',
};
