import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
    IAuction,
    IAuctionLotsQuery,
    ILot,
    IMissingAndUnassignedLots,
    QueryKeys,
    useDelete,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
    useSave,
} from '../../shared';
import { AuctionStatus } from '../../shared/enums/auction-status.enum';
import { auctionClient } from '../clients';

const client = auctionClient;
const listKey = QueryKeys.Auctions;
const itemKey = QueryKeys.Auction;

export function useAuctionsList(...args: UseListParams<IAuction>) {
    return useList(client, listKey, ...args);
}

export function useAuction(...args: UseItemParams<IAuction>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveAuction() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteAuction() {
    return useDelete(client, listKey);
}

export function useUpdateAuctionStatus(): UseMutationResult<
    void,
    AxiosError,
    { auctionId: string; status: AuctionStatus }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ auctionId, status }) => client.updateAuctionStatus(auctionId, status),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Auction] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Auctions] });
        },
    });
}

export function useGetMissingAuctionLots(auctionId: string) {
    return useQuery<IMissingAndUnassignedLots, AxiosError>({
        queryKey: [QueryKeys.AuctionMissingLots, auctionId],
        queryFn: () => client.getMissingAuctionLots(auctionId),
        enabled: !!auctionId,
    });
}

export function useGetAuctionLots(auctionId: string, query: IAuctionLotsQuery) {
    return useQuery<ILot[], AxiosError>({
        queryKey: [QueryKeys.Lots, auctionId, query],
        queryFn: () => client.getAuctionLots(auctionId, query),
        enabled: !!auctionId && !!query.firstLotNumber && !!query.lastLotNumber,
    });
}
