import { Button, Divider, Menu, MenuItem } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    auctionId: string;
}
export const PublicCatalogButton: FC<Props> = ({ auctionId }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                variant="outlined"
                id="public-catalog-button"
                aria-controls={open ? 'public-catalog-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {t('getPublicCatalog')}
            </Button>
            <Menu
                id="public-catalog-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    list: {
                        'aria-labelledby': 'public-catalog-button',
                    },
                }}
            >
                <MenuItem
                    sx={{ width: '167px' }}
                    onClick={() => window.open(`/dvc-auction-api/auctions/${auctionId}/public-catalog`, '__blank')}
                >
                    PDF
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                    sx={{ width: '167px' }}
                    onClick={() => window.open(`/dvc-auction-api/auctions/${auctionId}/public-catalog/csv`, '__blank')}
                >
                    CSV
                </MenuItem>
            </Menu>
        </>
    );
};
