import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormCard, IParams, ISupplierForm, Page } from '../../../shared';
import { SupplierDataInputs } from '../../components';
import { useSaveSupplier, useSupplier } from '../../hooks';
import { supplierFromFormMapper, supplierToFormMapper } from '../../mappers';
import { useSupplierSchema } from '../../validators';

export const SupplierEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: supplier, isFetching } = useSupplier(id);
    const { mutateAsync: saveSupplier, isPending: isPendingSave } = useSaveSupplier();

    const form = useForm<ISupplierForm>({
        resolver: yupResolver(useSupplierSchema()),
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (supplier) {
            form.reset(supplierToFormMapper(supplier));
        } else {
            form.reset({ address: { country: { code: 'be', label: 'België' } } });
        }
    }, [supplier, form]);

    const onSubmit = useCallback(
        async (item: ISupplierForm) => {
            const result = await saveSupplier({ id, item: supplierFromFormMapper(item) });
            navigate(`/article/suppliers/${result.id}/detail`);
        },
        [saveSupplier, id, navigate],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            <Button onClick={() => navigate('/article/suppliers')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={supplier ? `${supplier?.sequenceNumber} - ${supplier?.name}` : t('newSupplier')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isPendingSave}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <SupplierDataInputs id={id} form={form} />
                </FormCard>
            </FormProvider>
        </Page>
    );
};
