import { Autocomplete, AutocompleteChangeReason, TextField } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import React, { FC, useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useArticlesList } from '../../../article/hooks';
import { ILot, ILotArticleForm, useQueryParams } from '../../../shared';
import {
    selectedArticlesInfoToLotFormMapper,
    selectedArticleToLotFormMapper,
    setLotArticleDefaults,
} from '../../mappers/lot-article.mapper';
import { ArticleInUseWarning } from '../in-use-warnings/article-in-use-warning.component';

interface Props {
    index: number;
    lot?: ILot;
    auctionId?: string;
    setInUseWarning: (inUseWarning: boolean) => void;
}
export const SelectAutocompleteLotArticle: FC<Props> = ({ index, lot, auctionId, setInUseWarning }) => {
    const { t } = useTranslation();
    const { setValue, getValues, watch } = useFormContext();
    const { setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'articleNumber', sort: 'asc' as GridSortDirection }],
    });

    const { data: articles, isLoading } = useArticlesList({
        page: 1,
        pageSize: 10,
        search: debouncedSearch,
    });

    const options = useMemo(() => {
        const selected = getValues('articles')?.map(({ id }: ILotArticleForm) => id) || [];
        const ids = articles?.data?.map(({ id }) => id) || [];
        return ids?.filter((id) => !selected.includes(id));
    }, [watch(`articles.${index}.id`), articles]);

    const handleOnChange = useCallback(
        (event: AutocompleteChangeReason, id: string | null) => {
            if (event === 'clear') setLotArticleDefaults(index, setValue);

            const article = articles?.data.find((article) => article.id === id);
            if (article) {
                selectedArticleToLotFormMapper(article, index, setValue, getValues);
                if (!lot) selectedArticlesInfoToLotFormMapper(getValues, setValue);
            }
        },
        [articles?.data, index, setValue],
    );

    return (
        <Controller
            name={`articles.${index}.id`}
            defaultValue={null}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Autocomplete
                    id={index.toString()}
                    sx={{ minWidth: '250px' }}
                    value={value}
                    onInputChange={(_, value) => setSearch(value)}
                    onChange={(_, id, event) => {
                        handleOnChange(event, id);
                        onChange(id);
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('article')}
                            error={!!error}
                            helperText={error ? error.message : null}
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                    startAdornment: (
                                        <ArticleInUseWarning
                                            index={index}
                                            lot={lot}
                                            auctionId={auctionId}
                                            setInUseWarning={setInUseWarning}
                                            articles={articles?.data || []}
                                        />
                                    ),
                                },
                            }}
                            required
                        />
                    )}
                    options={options || []}
                    getOptionLabel={(id: string) => {
                        const article =
                            articles?.data.find((article) => article.id === id) ||
                            lot?.articles.find((article) => article.id === id);
                        if (article) return `${article?.consignment?.consignmentNumber}/${article?.articleNumber}`;
                        return '';
                    }}
                    noOptionsText={debouncedSearch ? t('noArticleFound') : t('startSearchingArticle')}
                    loading={isLoading}
                />
            )}
        />
    );
};
