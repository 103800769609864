import {
    ConsignmentType,
    IArticle,
    IArticleForm,
    IConsignment,
    IDimensions,
    ISaveArticle,
    mapDateToApiDate,
} from '../../shared';
import { consignmentToFormMapper } from './consignment.mapper';

export const setFormDefaults = (item?: IConsignment): Required<IArticleForm> => {
    return {
        consignment: item
            ? consignmentToFormMapper(item)
            : { consignmentNumberPart1: '', consignmentNumberPart2: '', date: null, supplier: null },
        articleNumber: 1,
        commission: item?.supplier?.standardCommission?.toString() || '',
        consignmentType: ConsignmentType.CG,
        shortDescription: '',
        descriptionNl: '',
        descriptionEn: '',
        minSalePrice: '',
        minEstimate: '',
        maxEstimate: '',
        estimateComment: '',
        artist: null,
        categories: [],
        dimensions: {
            width: '',
            height: '',
            depth: '',
            weight: '',
            length: '',
            diameter: '',
            weightUnit: 'gr',
            measurementUnit: 'mm',
        },
        paidToSupplier: false,
        documentation: [],
    };
};

export const articleDimensionsToFormMapper = (dimensions?: IDimensions) => {
    return {
        width: dimensions?.width || '',
        height: dimensions?.height || '',
        depth: dimensions?.depth || '',
        weight: dimensions?.weight || '',
        length: dimensions?.length || '',
        diameter: dimensions?.diameter || '',
        weightUnit: dimensions?.weightUnit || 'gr',
        measurementUnit: dimensions?.measurementUnit || 'mm',
    };
};

export const articleToFormMapper = (item: IArticle): IArticleForm => {
    return {
        ...item,
        categories: item.categories?.map((cat) => ({ ...cat, label: cat.descriptionNl })) || [],
        dimensions: articleDimensionsToFormMapper(item.dimensions),
        consignment: consignmentToFormMapper(item.consignment),
        documentation: item.documentation || [],
    };
};

export const articleFromFormMapper = (item: IArticleForm): ISaveArticle => {
    return {
        ...item,
        consignmentId: item.consignment.id,
        categoryIds: item.categories.map((category) => category.id),
        commission: Number(item.commission),
        minSalePrice: item.minSalePrice ? Number(item.minSalePrice) : null,
        minEstimate: item.minEstimate ? Number(item.minEstimate) : null,
        maxEstimate: item.maxEstimate ? Number(item.maxEstimate) : null,
        artistId: item.artist?.id || null,
        supplierId: item.consignment?.supplier?.id,
        consignmentNumber: `${item.consignment.consignmentNumberPart1}/${item.consignment.consignmentNumberPart2}`,
        date: mapDateToApiDate(item.consignment.date) || '',
    };
};
