import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IAuctioningLotForm, ILot, LabelValue, LotStatus, Widget } from '../../../shared';
import { useAuctioningLot } from '../../hooks';
import { auctioningLotFromFormMapper, auctioningLotToFormMapper } from '../../mappers/auctioning-lot.mapper';
import { useAuctioningLotSchema } from '../../validators/auctioning-lot.validator';
import { AuctioningLotInputs } from '../auctioning-lot-inputs/auctioning-lot-inputs.component';

interface Params {
    currentLot: ILot;
    onNext: () => void;
    auctionId: string;
}

export const ActiveSlotForm: FC<Params> = ({ currentLot, onNext, auctionId }) => {
    const { t } = useTranslation();
    const { mutateAsync: saveLot, isPending } = useAuctioningLot();

    const form = useForm<IAuctioningLotForm>({ resolver: yupResolver(useAuctioningLotSchema()), mode: 'onSubmit' });

    useEffect(() => {
        if (currentLot) {
            form.reset(auctioningLotToFormMapper(currentLot));
        }
    }, [currentLot, form]);

    const onSubmit = async (item: IAuctioningLotForm) => {
        if (currentLot) {
            await saveLot({ id: currentLot.id, item: { ...auctioningLotFromFormMapper(item) } });
            onNext();
        }
    };

    return (
        <Grid size={{ xs: 5 }}>
            <Widget>
                <FormProvider {...form}>
                    <form noValidate={true} autoComplete="off">
                        <Stack spacing={2}>
                            <Stack spacing={4} direction="row">
                                <Stack spacing={2} sx={{ pt: 0.5, pl: 1 }}>
                                    <LabelValue
                                        label={t('lotNumber')}
                                        value={`${currentLot?.lotNumber || ''} ${currentLot?.lotNumberSuffix || ''}`}
                                    />
                                    <LabelValue
                                        label={t('lotStatus')}
                                        value={`${
                                            [LotStatus.SOLD, LotStatus.NOT_SOLD].includes(currentLot.status)
                                                ? t(currentLot.status)
                                                : '-'
                                        }`}
                                    />
                                </Stack>

                                <Box>
                                    <Typography variant="caption">{t('description')}</Typography>
                                    <Typography
                                        sx={{
                                            whiteSpace: 'pre-line',
                                            width: '95%',
                                            height: '150px',
                                            overflow: 'scroll',
                                        }}
                                    >
                                        {currentLot.descriptionNl}
                                    </Typography>
                                </Box>
                            </Stack>

                            <AuctioningLotInputs auctionId={auctionId} />

                            <Stack direction="row" spacing={2}>
                                <Button
                                    size="large"
                                    variant="contained"
                                    onClick={() => {
                                        form.setValue('status', LotStatus.SOLD);
                                        form.handleSubmit((data) => onSubmit(data))();
                                    }}
                                    disabled={isPending}
                                    sx={{
                                        width: '100%',
                                        py: 2,
                                        backgroundColor: '#357a38',
                                        '&:hover': { backgroundColor: '#1b5e20' },
                                    }}
                                >
                                    {t(LotStatus.SOLD)}
                                </Button>
                                <Button
                                    size="large"
                                    variant="contained"
                                    onClick={() => {
                                        form.setValue('status', LotStatus.NOT_SOLD);
                                        form.handleSubmit((data) => onSubmit(data))();
                                    }}
                                    disabled={isPending}
                                    sx={{
                                        width: '100%',
                                        py: 2,
                                        backgroundColor: '#aa2e25',
                                        '&:hover': { backgroundColor: '#801313' },
                                    }}
                                >
                                    {t(LotStatus.NOT_SOLD)}
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </FormProvider>
            </Widget>
        </Grid>
    );
};
