import { Article } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    IArticle,
    IAuction,
    ILot,
    RowActions,
    SearchField,
    SortOrder,
    TableComponent,
    useQueryParams,
    Widget,
} from '../../../shared';
import { useLotsList } from '../../hooks';
import { AuctioningLotModal } from '../auctioning-lot-modal/auctioning-lot-modal.component';
import { CreditLots } from '../credit-lots/credit-lots.component';
import { RegisterPayment } from '../register-payment/register-payment.component';

interface Props {
    auction: IAuction;
}

export const OngoingAuctionLotTable: FC<Props> = ({ auction }) => {
    const { t } = useTranslation();
    const [updateLot, setUpdateLot] = useState<ILot | undefined>();

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'lotNumber', sort: 'asc' as GridSortDirection }],
        defaultPageSize: 20,
    });
    const { data: lots, isPending } = useLotsList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
        auctionId: auction.id,
    });

    const columns: GridColDef[] = [
        {
            field: 'lotNumber',
            headerName: t('lotNumber'),
            sortable: false,
            flex: 0.5,
            valueFormatter: (value, row) => `${row.lotNumber}${row.lotNumberSuffix || ''}`,
        },
        {
            field: 'articles',
            headerName: t('articles'),
            sortable: false,
            flex: 1,
            renderCell: ({ value }) => (
                <Stack direction="row" spacing={1}>
                    {value.map((article: IArticle, index: number) => (
                        <Fragment key={index}>
                            <a href={`/article/articles/${article.id}/detail`}>
                                {`${article.consignment?.consignmentNumber}/${article.articleNumber}`}
                            </a>
                            {index < value.length - 1 ? ', ' : ''}
                        </Fragment>
                    ))}
                </Stack>
            ),
        },
        {
            field: 'descriptionNl',
            headerName: t('descriptionNl'),
            sortable: false,
            flex: 2,
        },
        { field: 'buyerNumber', headerName: t('buyerNumber'), sortable: false, width: 120 },
        { field: 'hammerPrice', headerName: t('hammerPrice'), sortable: false, width: 120 },
        {
            field: 'status',
            headerName: t('lotStatus'),
            sortable: false,
            width: 180,
            valueFormatter: (value: string) => t(value),
        },
        {
            field: ' ',
            headerName: '',
            flex: 0.5,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <RowActions
                    onEdit={() => setUpdateLot(row)}
                    deleteWarningTitle={t('lotDeleteWarningTitle')}
                    deleteWarningText={t('lotDeleteWarningText')}
                />
            ),
        },
    ];

    return (
        <Widget
            title={
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="subtitle2">{t('lots')}</Typography>
                </Stack>
            }
            icon={<Article />}
            hasTable
            actions={
                <Stack direction="row" spacing={1}>
                    <RegisterPayment />
                    <CreditLots />
                    <SearchField search={search} onSearch={setSearch} />
                </Stack>
            }
        >
            <TableComponent
                rows={lots?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={lots?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
            />
            <AuctioningLotModal
                auctionId={auction.id}
                open={!!updateLot}
                close={() => {
                    setUpdateLot(undefined);
                }}
                lot={updateLot}
            />
        </Widget>
    );
};
