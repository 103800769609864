import { FC } from 'react';
import { Login } from '../../../auth';
import {
    DrawerContextProvider,
    Loading,
    PermissionsContext,
    useGetCurrentUser,
    useGetUserRoles,
} from '../../../shared';
import { Header } from '../header/header.component';
import { Routes } from '../routes/routes.component';

export const Main: FC = () => {
    const { data, isLoading: isUserLoading } = useGetCurrentUser();
    const { data: roles, isLoading: areRolesLoading } = useGetUserRoles(!!data?.data?.id);

    if (isUserLoading || areRolesLoading) <Loading fullscreen />;

    if (!data?.success) {
        return <Login />;
    }

    return (
        <PermissionsContext.Provider value={{ permissions: roles?.items }}>
            <DrawerContextProvider>
                <Header />
                <Routes />
            </DrawerContextProvider>
        </PermissionsContext.Provider>
    );
};
