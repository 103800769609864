import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
    IArticle,
    IArticleQuery,
    ISaveArticle,
    IArticleReturn,
    QueryKeys,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
    IArticleReturns,
    IArticleAvailableForPurchaseRegister,
} from '../../shared';
import { articleClient } from '../clients';

const client = articleClient;
const listKey = QueryKeys.Articles;
const itemKey = QueryKeys.Article;

export function useArticlesList(...args: UseListParams<IArticle, IArticleQuery>) {
    return useList(client, listKey, ...args);
}

export function useArticle(...args: UseItemParams<IArticle>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveArticle(): UseMutationResult<IArticle, AxiosError, { item: ISaveArticle; id?: string }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ item, id }) => (id ? client.updateOne(id, item) : client.createOne(item)),
        onSuccess: async ({ id }) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Articles] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Article, id] });

            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Consignment] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.NextArticleNumber] });
        },
    });
}

export function useDeleteArticle(): UseMutationResult<IArticle, AxiosError, string> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (articleId) => client.deleteOne(articleId),
        onSuccess: async ({ consignment }) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Articles] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Consignment, consignment.id] });
        },
    });
}

export function useReturnArticle(): UseMutationResult<IArticle, AxiosError, { id: string; item: IArticleReturn }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id, item }) => client.returnArticle(id, item),
        onSuccess: async (result) => {
            await queryClient.setQueryData([QueryKeys.Article, result.id], result);
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Articles] });
        },
    });
}

export function useReturnArticles(): UseMutationResult<void, AxiosError, IArticleReturns> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (item) => client.returnArticles(item),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Article] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Articles] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.ConsignmentArticles] });
        },
    });
}

export function useArticlesAvailableForPurchaseRegister(): UseQueryResult<
    IArticleAvailableForPurchaseRegister[],
    AxiosError
> {
    return useQuery({
        queryKey: [QueryKeys.Articles, QueryKeys.PurchaseRegisters],
        queryFn: () => client.getArticlesAvailableForPurchaseRegister(),
    });
}
