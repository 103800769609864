import React, { FC } from 'react';
import { formatDateTimeString, IParams, SortOrder, TableComponent, useQueryParams, Widget } from '../../../shared';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { useLotsList } from '../../../auction/hooks';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

export const ArticleDetailHistory: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'updatedDate', sort: 'desc' as GridSortDirection }],
    });

    const { data: lots, isPending } = useLotsList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        articleId: id,
    });

    const columns: GridColDef[] = [
        {
            field: 'updatedDate',
            headerName: t('lotUpdatedDate'),
            minWidth: 100,
            flex: 0.5,
            valueFormatter: (value: string) => formatDateTimeString(value),
        },
        {
            field: 'auction',
            headerName: t('auctionNumber'),
            sortable: false,
            minWidth: 100,
            flex: 0.3,
            renderCell: ({ row: { auction } }: GridCellParams) => (
                <a href={`/auction/auctions/${auction?.id}/detail`}> {`${auction?.auctionNumber}`}</a>
            ),
        },
        {
            field: 'lotNumber',
            headerName: t('lotNumber'),
            sortable: false,
            minWidth: 100,
            flex: 0.3,
        },
        {
            field: 'status',
            headerName: t('lotStatus'),
            sortable: false,
            minWidth: 100,
            flex: 0.5,
            valueFormatter: (value: string) => t(value),
        },
    ];

    return (
        <Widget title={t('history')} hasTable small>
            {(lots?.data || [])?.length > 0 ? (
                <TableComponent
                    rows={lots?.data || []}
                    columns={columns}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    rowCount={lots?.pagination.size || 0}
                    loading={isPending}
                    sortModel={sort}
                    onSortModelChange={setSort}
                />
            ) : (
                <Typography variant="caption" fontStyle="italic">
                    {t('noHistory')}
                </Typography>
            )}
        </Widget>
    );
};
