import {
    auctionApi,
    BaseClient,
    IAuction,
    IAuctionLotsQuery,
    ILot,
    IMissingAndUnassignedLots,
    ISaveAuction,
} from '../../shared';
import { AuctionStatus } from '../../shared/enums/auction-status.enum';

class AuctionClient extends BaseClient<IAuction, ISaveAuction> {
    constructor() {
        super(auctionApi, '/auctions');
    }

    public async updateAuctionStatus(auctionId: string, status: AuctionStatus): Promise<void> {
        await this.api.put<void>(`${this.basePath}/${auctionId}/status`, { status });
    }

    public async getMissingAuctionLots(auctionId: string): Promise<IMissingAndUnassignedLots> {
        const { data } = await this.api.get<IMissingAndUnassignedLots>(`${this.basePath}/${auctionId}/missing-lots`);
        return data;
    }

    public async getAuctionLots(auctionId: string, params: IAuctionLotsQuery): Promise<ILot[]> {
        const { data } = await this.api.get<ILot[]>(`${this.basePath}/${auctionId}/lots`, { params });
        return data;
    }
}

export const auctionClient = new AuctionClient();
