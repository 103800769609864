import { auctionApi, BaseClient } from '../../shared';
import {
    ICreateSupplierPayment,
    ISupplierPayment,
    ISupplierPaymentQuery,
} from '../../shared/models/supplier-payment.model';

class SupplierPaymentClient extends BaseClient<ISupplierPayment, ICreateSupplierPayment, ISupplierPaymentQuery> {
    constructor() {
        super(auctionApi, '/supplier-payments');
    }

    public async createSupplierPayment(item: ICreateSupplierPayment): Promise<void> {
        await this.api.post<void>(`${this.basePath}`, item);
    }
}

export const supplierPaymentClient = new SupplierPaymentClient();
