import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGrid, IArticle, LabelValue, Widget } from '../../../shared';

interface Props {
    article: IArticle;
}

export const ArticleDetailDescription: FC<Props> = ({ article }) => {
    const { t } = useTranslation();
    const categories = article?.categories?.map((category) => category.descriptionNl).join(', ');
    const documentation = article?.documentation?.map((item) => t(`documentation_options.${item}`)).join(', ') || '';

    return (
        <Widget title={t('description')} small>
            <LabelValue
                label={t('artist')}
                value={
                    article.artist ? (
                        <a href={`/admin/artists/${article.artist?.id}/edit`}>
                            {`${article.artist?.lastname} ${article.artist?.firstname}`}
                        </a>
                    ) : (
                        '-'
                    )
                }
            />
            <LabelValue label={t('categories')} value={categories} />
            <LabelValue label={t('documentation')} value={documentation} />

            <FormGrid containerProps={{ spacing: 0 }} size={{ xs: 6, md: 4 }}>
                <LabelValue
                    label={t('width')}
                    value={
                        article.dimensions?.width
                            ? `${article.dimensions.width} ${article.dimensions.measurementUnit}`
                            : ''
                    }
                />
                <LabelValue
                    label={t('height')}
                    value={
                        article.dimensions?.height
                            ? `${article.dimensions.height} ${article.dimensions.measurementUnit}`
                            : ''
                    }
                />
                <LabelValue
                    label={t('depth')}
                    value={
                        article.dimensions?.depth
                            ? `${article.dimensions.depth} ${article.dimensions.measurementUnit}`
                            : ''
                    }
                />
                <LabelValue
                    label={t('length')}
                    value={
                        article.dimensions?.length
                            ? `${article.dimensions?.length} ${article.dimensions?.measurementUnit}`
                            : ''
                    }
                />
                <LabelValue
                    label={t('diameter')}
                    value={
                        article.dimensions?.diameter
                            ? `${article.dimensions?.diameter} ${article.dimensions?.measurementUnit}`
                            : ''
                    }
                />
                <LabelValue
                    label={t('weight')}
                    value={
                        article.dimensions?.weight
                            ? `${article.dimensions?.weight} ${article.dimensions?.weightUnit}`
                            : ''
                    }
                />
            </FormGrid>
            <LabelValue label={t('descriptionNl')} value={article.descriptionNl} sx={{ whiteSpace: 'pre-line' }} />
            <LabelValue label={t('descriptionEn')} value={article.descriptionEn} sx={{ whiteSpace: 'pre-line' }} />
        </Widget>
    );
};
