import { Download } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'signature_pad';
import { ILot, ILotForSignature, Widget } from '../../../shared';
import { useSaveSignature } from '../../hooks';

interface Props {
    currentLot: ILotForSignature;
}

export const DrawSignature: FC<Props> = ({ currentLot }) => {
    const { t } = useTranslation();
    const [resign, setResign] = useState(false);
    const [signaturePad, setSignaturePad] = useState<SignaturePad>();
    const ref = useRef<HTMLCanvasElement>(null);

    const { mutateAsync: saveSignature } = useSaveSignature();

    useEffect(() => {
        let pad: SignaturePad;
        if (ref.current) {
            pad = new SignaturePad(ref.current);
            setSignaturePad(pad);
        }
        return () => {
            if (pad) {
                pad.off();
            }
            setResign(false);
        };
    }, [ref, currentLot]);

    const onSubmit = async () => {
        if (signaturePad && !signaturePad.isEmpty()) {
            await saveSignature({ lotId: currentLot.id, dataUrl: signaturePad.toDataURL() });
        }
    };
    return (
        <Widget title={t('signature')}>
            <canvas
                id="canvas"
                width="490"
                height="490"
                ref={ref}
                style={{
                    border: '1px solid',
                    display: !currentLot.signature || resign ? 'block' : 'none',
                    marginBottom: '8px',
                }}
            />

            <Stack direction="row" spacing={1}>
                {currentLot.signature && !resign && (
                    <Stack direction="row">
                        <Button
                            startIcon={<Download />}
                            variant="outlined"
                            onClick={() => window.open(`/dvc-auction-api/lots/${currentLot?.signature?.id}/download`)}
                        >
                            {t('signature')}
                        </Button>
                    </Stack>
                )}
                {!currentLot.signature || resign ? (
                    <>
                        <Button variant="outlined" onClick={() => signaturePad?.clear()}>
                            {t('clear')}
                        </Button>
                        <Button variant="contained" onClick={onSubmit}>
                            {t('save')}
                        </Button>
                    </>
                ) : (
                    <Button variant="outlined" onClick={() => setResign(true)}>
                        {t('resign')}
                    </Button>
                )}
            </Stack>
        </Widget>
    );
};
