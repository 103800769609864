import { Box, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface Props {
    children: ReactNode;
    title?: ReactNode;
    footer?: ReactNode;
    sx?: SxProps<Theme>;
}

export const Section: FC<Props> = ({ children, title, footer, sx }) => {
    return (
        <Box sx={{ ...sx }}>
            {title && (
                <Typography variant="subtitle2" sx={{ mb: 2 }}>
                    {title}
                </Typography>
            )}
            <Stack spacing={2} alignItems="flex-start">
                {children}
            </Stack>
            <Box sx={{ mt: 1 }}>{footer}</Box>
        </Box>
    );
};
