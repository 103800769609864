import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QueryKeys, useList, UseListParams } from '../../shared';
import {
    ICreateSupplierPayment,
    ISupplierPayment,
    ISupplierPaymentQuery,
} from '../../shared/models/supplier-payment.model';
import { supplierPaymentClient } from '../clients/supplier-payment.client';

const client = supplierPaymentClient;
const listKey = QueryKeys.SupplierPayments;

export function useSupplierPaymentsList(...args: UseListParams<ISupplierPayment, ISupplierPaymentQuery>) {
    return useList(client, listKey, ...args);
}

export function useCreateSupplierPayment(): UseMutationResult<void, AxiosError, ICreateSupplierPayment> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (item) => client.createSupplierPayment(item),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [listKey] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.Articles] });
        },
    });
}
