import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import {
    getNationalities,
    ControlledAutocomplete,
    ControlledInput,
    DialogTitleWithClose,
    FormGrid,
    IArtist,
    IArtistForm,
} from '../../../shared';
import { useSaveArtist } from '../../hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { artistFromFormMapper } from '../../mappers';
import { useTranslation } from 'react-i18next';
import { useArtistSchema } from '../../validators';

interface Props {
    open: { show: boolean; value: string };
    close: (artist?: IArtist) => void;
}

export const ArtistDialog: FC<Props> = ({ open, close }) => {
    const { t } = useTranslation();
    const { mutateAsync: saveArtist, isPending } = useSaveArtist();

    const form = useForm<IArtistForm>({
        resolver: yupResolver(useArtistSchema()),
        mode: 'all',
    });

    useEffect(() => {
        form.reset();
    }, [open, form]);

    const onSubmit = useCallback(
        async (item: IArtistForm) => {
            const artist = await saveArtist({ item: artistFromFormMapper(item) });
            close(artist);
        },
        [saveArtist, close],
    );

    const actions = useMemo(
        () => [
            <Button
                variant="contained"
                color="primary"
                onClick={form.handleSubmit(onSubmit)}
                disabled={isPending}
                key="save"
            >
                {t('save')}
            </Button>,
            <Button onClick={() => close()} color="secondary" key="cancel">
                {t('cancel')}
            </Button>,
        ],
        [close, form, isPending, onSubmit, t],
    );

    return (
        <Dialog open={open.show} onClose={() => close()} fullWidth maxWidth="sm">
            <DialogTitleWithClose onClose={() => close()}>{t('newArtist')}</DialogTitleWithClose>
            <FormProvider {...form}>
                <DialogContent>
                    <FormGrid size={{ xs: 12 }} containerProps={{ pt: 2 }}>
                        <ControlledInput name="lastname" label={t('lastname')} required />
                        <ControlledInput name="firstname" label={t('firstname')} required />
                        <ControlledAutocomplete
                            name="nationality"
                            defaultValue={null}
                            label={t('nationality')}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            options={getNationalities}
                        />
                        <ControlledInput name="yearOfBirth" label={t('yearOfBirth')} />
                        <ControlledInput name="yearOfDeath" label={t('yearOfDeath')} />
                    </FormGrid>
                </DialogContent>
                <DialogActions children={actions} />
            </FormProvider>
        </Dialog>
    );
};
