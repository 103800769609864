import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, ILot, IPlatform, LotStatus, Widget } from '../../../shared';

interface Params {
    status: LotStatus;
    lots?: ILot[];
}

export const ActiveSlotHistory: FC<Params> = ({ status, lots = [] }) => {
    const { t } = useTranslation();
    const filteredLots = lots
        .filter((lot) => lot.status === status)
        .sort((a, b) => (b.lotNumberSuffix || 'a').localeCompare(a.lotNumberSuffix || 'a'))
        .sort((a, b) => b.lotNumber - a.lotNumber);
    const columns: GridColDef[] = [
        {
            field: 'lotNumber',
            headerName: t('lot'),
            sortable: false,
            minWidth: 50,
            valueFormatter: (_, row) => `${row.lotNumber}${row.lotNumberSuffix || ''}`,
        },
        {
            field: 'buyerNumber',
            headerName: t('buyerNumber'),
            sortable: false,
            minWidth: 50,
        },
        {
            field: 'hammerPrice',
            headerName: t('hammerPrice'),
            sortable: false,
            minWidth: 50,
            valueFormatter: (value: number) => formatPrice(value),
        },
        {
            field: 'platform',
            headerName: t('platform'),
            sortable: false,
            minWidth: 50,
            valueFormatter: (value: IPlatform) => value?.name,
        },
    ];

    return (
        <Grid size={{ xs: 3.5 }} sx={{ height: '100%' }}>
            <Widget hasTable sx={{ '.MuiCardContent-root': { p: 0, overflow: 'scroll' } }}>
                <DataGrid
                    className="history"
                    rows={filteredLots}
                    columns={columns}
                    disableColumnFilter
                    disableColumnMenu
                    columnHeaderHeight={40}
                    rowHeight={45}
                    hideFooter
                    slots={{ noRowsOverlay: () => <Box display="hidden" /> }}
                />
            </Widget>
        </Grid>
    );
};
